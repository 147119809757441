import React from "react";
import { Input } from "antd";
import { Link } from "react-router-dom";
import { Logo } from "../../constant/images";
import { SlLocationPin } from "react-icons/sl";
import { LuPhone } from "react-icons/lu";
import { TbMail } from "react-icons/tb";
import { FaLinkedinIn, FaFacebookF, FaInstagram } from "react-icons/fa6";
import { IoArrowForward } from "react-icons/io5";

const Footer = () => {
    return(
        <div className="footerWraper">
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="footerCol">
                                <div className="footerLogo">
                                    <img src={Logo} className="img-fluid" alt="logo" />
                                </div>
                                <p className="address">
                                    <SlLocationPin />
                                    2728 Hickory StreetSalt California, UT 84104
                                </p>
                                <p className="phone">
                                    <LuPhone /> +91 987-654-3210
                                </p>
                                <p className="email">
                                    <TbMail />
                                    support@rainfrog.com
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footerCol">
                                <h4 className="footerColTitle">Quick Links</h4>
                                <div className="footerLinks">
                                    <ul className="footerMenuList">
                                        <li className="footerMenuItem">
                                            <Link to={"/"}>Home</Link>
                                        </li>
                                        <li className="footerMenuItem">
                                            <Link to={"/"}>About</Link>
                                        </li>
                                        <li className="footerMenuItem">
                                            <Link to={"/contact-us"}>Contact Us</Link>
                                        </li>
                                        <li className="footerMenuItem">
                                            <Link to={"/property-listing"}>Property Listing</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footerCol">
                                <h4 className="footerColTitle">Discovery</h4>
                                <div className="footerLinks">
                                    <ul className="footerMenuList">
                                        <li className="footerMenuItem">
                                            <Link to={"/"}>India</Link>
                                        </li>
                                        <li className="footerMenuItem">
                                            <Link to={"/"}>United State</Link>
                                        </li>
                                        <li className="footerMenuItem">
                                            <Link to={"/"}>Germany</Link>
                                        </li>
                                        <li className="footerMenuItem">
                                            <Link to={"/"}>Africa</Link>
                                        </li>
                                        <li className="footerMenuItem">
                                            <Link to={"/"}>India</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footerCol">
                                <h4 className="footerColTitle mb-4">Subscribe to our Newsletter!</h4>
                                <div className="inpurSection">
                                    <Input placeholder="Enter email here" className="newsLetterInput"/>
                                    <button type="button" className="btn sendBtn">
                                        <IoArrowForward />
                                    </button>
                                </div>
                                <h4 className="footerColTitle mb-2">Follow Us on</h4>
                                <div className="footerSocial">
                                    <Link to={"/"} className="socialLink"><FaLinkedinIn /></Link>
                                    <Link to={"/"} className="socialLink"><FaFacebookF /></Link>
                                    <Link to={"/"} className="socialLink"><FaInstagram /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyWriteFooter">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <p className="copyWrite">
                                ©Copyright – All rights reserved.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <div className="footerMenu">
                                <div className="menuList">
                                    <Link to={"/terms-and-conditions"} className="footerMenuLink">Terms and Conditions</Link>
                                    <Link to={"/privacy-policy"} className="footerMenuLink">Privacy Policy</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
