import React from "react";

const Map = ({height}) => {
    return (
        <div className="mapWrapper">
            <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d104374.41471243976!2d-117.96062660317425!3d35.16409044229692!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c22cb54dbe54b9%3A0x2134dda715410ab2!2sCalifornia%20City%2C%20CA%2093505%2C%20USA!5e0!3m2!1sen!2sin!4v1725286131761!5m2!1sen!2sin" 
                width="100%" 
                height={height}
                title="map"
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>
    );
}

export default Map;