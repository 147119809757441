import React, { useEffect, useState } from "react";
import { Image, Upload, message, Button, Form, Input, } from "antd";
import { UserAvatar } from "../../constant/images";
import { GoPencil } from "react-icons/go";
import { useChangePasswordMutation, useUpdateProfileMutation, } from "../../store/apiSlice";
import { useGetUserDetailQuery } from "../../store/getSlice";

const LoginInformation = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [isEditingName, setIsEditingName] = useState(false);
    const [changePassword] = useChangePasswordMutation()
    const [updateProfile] = useUpdateProfileMutation()
    const { data: userDetail } = useGetUserDetailQuery()
    const [messageApi, contextHolder] = message.useMessage();
    const [name, setName] = useState('');

    const success = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    const showPasswordFields = () => {
        setIsEditing(true);
    };

    const hidePasswordFields = async () => {
        setIsEditing(false);

    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleSaveName = async () => {

        let data = {
            name: name
        }
        try {
            const res = await updateProfile(data)
            if (res.data) {
                success(res.data.message)
                setIsEditingName(false);
            } else {
                errorMessage(res.error.data.message)
            }
        } catch (error) {
            errorMessage("An unexpected error occurred")
        }

    };

    const handleUploadChange = (info) => {
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    const onFinish = async (values) => {
        try {
            const res = await changePassword(values)
            if (res.data) {
                success(res.data.message)
            } else {
                errorMessage(res.error.data.message)
            }
        } catch (error) {
            errorMessage('An unexpected error occurred')
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        setName(userDetail?.data?.name)
    }, [userDetail])

    return (
        <div className="card accountCard loginCard">
            <div className="card-body">

                <div className="userProfile">
                    <div className="userImageSection">
                        <div className="userImage">
                            <Image src={UserAvatar} className="img-fluid" alt="Avatar" preview={false} />
                            <div className="uploadBtn">
                                <Upload
                                    name="avatar"
                                    showUploadList={false}
                                    onChange={handleUploadChange}
                                >
                                    <Button icon={<GoPencil />}></Button>
                                </Upload>
                            </div>
                        </div>

                    </div>
                    <div className="userNameRow">
                        {!isEditingName ? (
                            <>
                                <h3 className="userName">{name}</h3>
                                <button type="button" className="btn pencilBtn" onClick={() => setIsEditingName(true)}><GoPencil /></button>
                            </>
                        ) : (
                            <div className="nameEditWrapper">
                                <Input
                                    value={name}
                                    onChange={handleNameChange}
                                    className="form-control"
                                    placeholder="Enter new name"
                                />
                                <div className="my-4">
                                    <button onClick={handleSaveName} className="btn submitBtn me-4">Save</button>
                                    <button onClick={() => setIsEditingName(false)} className="btn cancelBtn">Cancel</button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="card-header">
                <h4 className="card-title">Login Information</h4>
            </div>
            <div className="card-body">

                <div className="userInforWrap">
                    <div className="mb-3">
                        <label>Email</label>
                        <p className="userInfo">{userDetail?.data?.email}</p>
                    </div>
                    {!isEditing ? (
                        <div className="showHideDiv">
                            <div className="mb-3">
                                <label>Password</label>
                                <p className="userInfo">********</p>
                            </div>
                            <div className="mb-3">
                                <button type="button" className="btn editFormBtn" onClick={showPasswordFields}>Edit</button>
                            </div>
                        </div>
                    ) : (
                        <div className="passwordInputsRow">
                            {contextHolder}
                            <Form
                                layout="vertical"
                                name="passwordForm"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Form.Item
                                    label="Current Password"
                                    name="current_password"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your current  password!",
                                        },

                                    ]}
                                >
                                    <Input.Password
                                        className="form-control"
                                        placeholder="Enter password here"
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="New Password"
                                    name="new_password"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your password!",
                                        },
                                        {
                                            pattern:
                                                /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                            message:
                                                "Password must be minimum 8 characters, include at least one uppercase letter, one lowercase letter, one number, and one special character.",
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        className="form-control"
                                        placeholder="Enter password here"
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Confirm Password"
                                    name="new_password_confirmation"
                                    dependencies={["password"]}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your Confirm password!",
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (
                                                    !value ||
                                                    getFieldValue("new_password") === value
                                                ) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    "The passwords that you entered do not match!"
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password
                                        className="form-control"
                                        placeholder="Enter password here"
                                    />
                                </Form.Item>
                                <div className="buttonWrapper">
                                    <Button htmlType="submit" className="submitBtn me-4">Save</Button>
                                    <button type="button" className="btn cancelBtn" onClick={hidePasswordFields}>Cancel</button>
                                </div>
                            </Form>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LoginInformation;