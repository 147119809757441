import React, { useState, useEffect } from "react";
import { Logo } from "../../constant/images";
import { Button, Form, Input, Select, Spin, message, Modal, Checkbox } from "antd";
import { useUserRegisterMutation } from "../../store/loginRegisterSlice";
import { useNavigate, Link } from "react-router-dom";
import { useGetRegisterUserDetailsQuery } from "../../store/getSlice";
import { BiPlus, BiMinus } from "react-icons/bi";

const SignUpPage = () => {
    const [form] = Form.useForm();
    const [modal] = Form.useForm()
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isBenifitsOpen, setIsBenifitsOpen] = useState(false);
    const [userDetails, setUserDetail] = useState();
    const [activeTab, setActiveTab] = useState("gold");
    const maxFields = 3;

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("token");
    const [userRegister, { isLoading }] = useUserRegisterMutation();
    const { data: getRegisterUserDetails, isLoading: isUserDataLoading } =
        useGetRegisterUserDetailsQuery(token);

    const showModal = () => {
        if (userDetails) {
            modal.resetFields()
            setIsModalOpen(true);
        }
    };

    const handleCheckboxChange = (e) => {
        if (e.target.checked) {
            setIsBenifitsOpen(true);
        }
    };
    
    const showBenifitsModal = () => {
        setIsBenifitsOpen(true);
    };
    
    const hideBenifitsModal = () => {
        setIsBenifitsOpen(false);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        modal.resetFields()
    };

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const success = (message) => {
        messageApi.open({
            type: "success",
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: "error",
            content: message,
        });
    };

    const validateExpiryDate = (_, value) => {
        if (!value) {
            return Promise.reject('Please enter the expiry date');
        }

        const date = new Date(value);
        const currentDate = new Date();
        const month = date.getMonth();
        const year = date.getFullYear();

        if (year < currentDate.getFullYear() || (year === currentDate.getFullYear() && month < currentDate.getMonth())) {
            return Promise.reject('The expiry date cannot be in the past');
        }

        return Promise.resolve();
    };

    const onFinish = async (values) => {
        const date = new Date(values.exp_date);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString().slice(-2);
        const formattedDate = `${month}/${year}`;

        let data = {
            name_on_card: values.name_on_card,
            credit_card_number: values.credit_card_number,
            exp_date: formattedDate,
            cvv: values.cvv,
            ...userDetails,
        }

        try {
            const res = await userRegister(data);
            if (res.data) {
                setIsModalOpen(false);
                form.resetFields()
                modal.resetFields()
                success(res.data.message);
                setInterval(() => {
                    navigate("/");
                }, 1000);
            } else {

                errorMessage(res.error.data.message);
            }
        } catch (error) {
            errorMessage("An unexpected error occurred");
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    useEffect(() => {
        if (getRegisterUserDetails?.data) {
            form.setFieldsValue(getRegisterUserDetails?.data);
        } else {
            form.resetFields();
        }
    }, [getRegisterUserDetails]);

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        console.log("Active Tab ID:", tabId);
    };

    return (
        <>
            {isUserDataLoading ? (
                <div className="spinContainer">
                    <Spin size="large" />
                </div>
            ) : (
                <div className="authWrapper signUpWrapper">
                    <div className="innerWrapper">
                        <div className="container">
                            {contextHolder}
                            <div className="row justify-content-center">
                                <div className="col-lg-12 col-md-12">
                                    <div className="card authCard signUpFormCard">
                                        <div className="card-body">
                                            <Form
                                                form={form}
                                                name="authForm"
                                                layout="vertical"
                                                onFinish={(value) => setUserDetail(value)}
                                                onFinishFailed={onFinishFailed}
                                                autoComplete="off"
                                                initialValues={{
                                                    Data: [{ zip_code: '', }],
                                                }}
                                            >
                                                <div className="row">
                                                    <div className="col-lg-6 d-flex align-items-center">
                                                        <div className="formWrapper signUpWrap">
                                                            <div className="card signUpCard">
                                                                <div className="card-body">
                                                                    <div className="logoSection">
                                                                        <img
                                                                            src={Logo}
                                                                            className="img-fluid"
                                                                            alt="logo"
                                                                        />
                                                                    </div>
                                                                    <div className="formSection">
                                                                        <h4 className="authTitle">Sign Up</h4>
                                                                        <p className="subTitle">
                                                                            Welcome! Please enter your details.
                                                                        </p>
                                                                        <div className="fomrWrap">
                                                                            <Form.Item
                                                                                label="Name"
                                                                                name="name"
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message:
                                                                                            "Please input your username!",
                                                                                    },
                                                                                    {
                                                                                        pattern: /^[A-Za-z\s]+$/,
                                                                                        message: 'Name can only contain letters and spaces',
                                                                                    },
                                                                                    {
                                                                                        max: 25,
                                                                                        message: 'Name cannot exceed 25 characters',
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <Input
                                                                                    className="form-control"
                                                                                    placeholder="Name"
                                                                                />
                                                                            </Form.Item>

                                                                            <Form.Item
                                                                                label="Email"
                                                                                name="email"
                                                                                rules={[
                                                                                    {
                                                                                        type: "email",
                                                                                        message:
                                                                                            "The input is not valid E-mail!",
                                                                                    },
                                                                                    {
                                                                                        required: true,
                                                                                        message: "Please input your email!",
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <Input
                                                                                    className="form-control"
                                                                                    placeholder="Enter your email"
                                                                                />
                                                                            </Form.Item>
                                                                            <div className="py-4">
                                                                                <p className="orText"></p>
                                                                            </div>

                                                                            <h4 className="authTitle">
                                                                                Set Your Password
                                                                            </h4>
                                                                            <p className="subTitle">
                                                                                Please set a strong password
                                                                            </p>

                                                                            <Form.Item
                                                                                label="Password"
                                                                                name="password"
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message:
                                                                                            "Please input your password!",
                                                                                    },
                                                                                    {
                                                                                        pattern:
                                                                                            /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                                                                        message:
                                                                                            "Password must be minimum 8 characters, include at least one uppercase letter, one lowercase letter, one number, and one special character.",
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <Input.Password
                                                                                    className="form-control"
                                                                                    placeholder="Enter password here"
                                                                                />
                                                                            </Form.Item>

                                                                            <Form.Item
                                                                                label="Confirm Password"
                                                                                name="password_confirmation"
                                                                                dependencies={["password"]}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message:
                                                                                            "Please input your Confirm password!",
                                                                                    },
                                                                                    ({ getFieldValue }) => ({
                                                                                        validator(rule, value) {
                                                                                            if (
                                                                                                !value ||
                                                                                                getFieldValue("password") ===
                                                                                                value
                                                                                            ) {
                                                                                                return Promise.resolve();
                                                                                            }
                                                                                            return Promise.reject(
                                                                                                "The passwords that you entered do not match!"
                                                                                            );
                                                                                        },
                                                                                    }),
                                                                                ]}
                                                                            >
                                                                                <Input.Password
                                                                                    className="form-control"
                                                                                    placeholder="Enter password here"
                                                                                />
                                                                            </Form.Item>
                                                                        </div>
                                                                        <div className="signUpRow text-start">
                                                                            <p>
                                                                                Already have an account?
                                                                                <Link to={"/login"}> Sign In</Link>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="rightSection">
                                                            <div className="card planCard">
                                                                <div className="card-body">
                                                                    <h4 className="planTitle">Plan Details</h4>
                                                                    <div className="row">
                                                                        {/* <div className="col-md-12">
                                                                            <div className="tabsWrapper">
                                                                                <button
                                                                                    type="button"
                                                                                    id="gold"
                                                                                    className={activeTab === "gold" ? "btn primaryButton active" : "btn primaryButton"}
                                                                                    onClick={() => handleTabClick("gold")}
                                                                                >
                                                                                    Gold
                                                                                </button>
                                                                                <button
                                                                                    type="button"
                                                                                    id="silver"
                                                                                    className={activeTab === "silver" ? "btn primaryButton active" : "btn primaryButton"}
                                                                                    onClick={() => handleTabClick("silver")}
                                                                                >
                                                                                    Silver
                                                                                </button>
                                                                                <button
                                                                                    type="button"
                                                                                    id="bronze"
                                                                                    className={activeTab === "bronze" ? "btn primaryButton active" : "btn primaryButton"}
                                                                                    onClick={() => handleTabClick("bronze")}
                                                                                >
                                                                                    Bronze
                                                                                </button>
                                                                            </div>
                                                                        </div> */}
                                                                        <div className="col-md-6">
                                                                            <Form.Item
                                                                                label="Days Free"
                                                                                name="days_free"
                                                                            >
                                                                                <Input
                                                                                    className="form-control"
                                                                                    placeholder="Days Free"
                                                                                    defaultValue="7 Days"
                                                                                    disabled
                                                                                />
                                                                            </Form.Item>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <Form.Item
                                                                                label="Subscription Amount"
                                                                                name="amount"
                                                                            >
                                                                                <Input
                                                                                    className="form-control"
                                                                                    placeholder="Subscription Amount"
                                                                                    defaultValue="$9.95"
                                                                                    disabled
                                                                                />
                                                                            </Form.Item>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <Form.Item
                                                                                label="Recurring Interval"
                                                                                name="intervel"
                                                                            >
                                                                                <Select
                                                                                    className="form-control-check"
                                                                                    onChange={handleChange}
                                                                                    defaultValue={"Weekly"}
                                                                                    disabled
                                                                                >
                                                                                    <Select.Option value="weekly">
                                                                                        Weekly
                                                                                    </Select.Option>
                                                                                    <Select.Option value="monthly">
                                                                                        Monthly
                                                                                    </Select.Option>
                                                                                    <Select.Option value="quarterly">
                                                                                        Quarterly
                                                                                    </Select.Option>
                                                                                    <Select.Option value="yearly">
                                                                                        Yearly
                                                                                    </Select.Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <Form.List
                                                                                name="Data"
                                                                            >
                                                                                {(fields, { add, remove }) => (
                                                                                    <>
                                                                                        {fields.slice(0, maxFields).map((field, index) => (
                                                                                            <div key={`${field.key}-${index}`}>
                                                                                                <Form.Item
                                                                                                    label={<span className="labelText">Zip Code <span className="smallText">(By clicking + button, you can add more ZIP)</span></span>}
                                                                                                    name={[field.name, 'zip_code']}
                                                                                                    rules={[
                                                                                                        {
                                                                                                            required: true,
                                                                                                            message: "Please input your Zip Code!"

                                                                                                        },
                                                                                                    ]}
                                                                                                    className="formAddon"
                                                                                                    max={2}
                                                                                                >
                                                                                                    <Input
                                                                                                        className="form-control"
                                                                                                        placeholder="Zip code"

                                                                                                    />
                                                                                                    {index === fields.length - 1 && (
                                                                                                        <button type="button" className="btn addMoreBtn" onClick={() => add()}>
                                                                                                            <BiPlus color="white" />
                                                                                                        </button>
                                                                                                    )}
                                                                                                    {fields.length > 1 && index < fields.length - 1 && (
                                                                                                        <div className="removeBtnWrapper">
                                                                                                            <button type="button" className="btn addMoreBtn" onClick={() => remove(field.name)}>
                                                                                                                <BiMinus color="white" />
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Form.Item>
                                                                                            </div>
                                                                                        ))}
                                                                                    </>
                                                                                )}
                                                                            </Form.List>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <Form.Item
                                                                                name="remember"
                                                                                valuePropName="checked"
                                                                            >
                                                                                <Checkbox 
                                                                                    className="customCheckbox"
                                                                                    onChange={handleCheckboxChange}
                                                                                >
                                                                                    Show me the Benifits.
                                                                                </Checkbox>
                                                                            </Form.Item>
                                                                            <Form.Item
                                                                                name="terms"
                                                                                valuePropName="checked"
                                                                            >
                                                                                <Checkbox className="customCheckbox">
                                                                                    I agree to our <Link to={'/'}>Terms of Service</Link> and <Link to={'/'}> Privacy Policy</Link>,<Link to={'/'}> Contact Us</Link> .
                                                                                </Checkbox>
                                                                            </Form.Item>
                                                                        </div>
                                                                        <div className="col-md-12">

                                                                            <Button
                                                                                type="button"
                                                                                htmlType="submit"
                                                                                onClick={showModal}
                                                                                className="primaryButton"
                                                                            >
                                                                                {isLoading
                                                                                    ? "Loading..."
                                                                                    : "Proceed"}
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Modal
                title="Add Payment Details"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleOk}
                className="paymentModal"
                footer={false}
            >
                <div className="modalBody">
                    <Form
                        name="paymentModal"
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <div className="row">
                            <div className="col-md-12">
                                <Form.Item name="name_on_card" label="Cardholder’s Name"
                                    rules={[
                                        { required: true, message: 'Please enter the cardholder’s name' },
                                        {
                                            pattern: /^[A-Za-z\s]+$/,
                                            message: 'Cardholder’s name can only contain letters and spaces',
                                        },
                                        {
                                            max: 25,
                                            message: 'Cardholder’s name cannot exceed 25 characters',
                                        },
                                    ]}>
                                    <Input className="form-control" placeholder="John Doe" />
                                </Form.Item>
                            </div>
                            <div className="col-md-12">
                                <Form.Item name="credit_card_number" label="Card Number"
                                    rules={[
                                        { required: true, message: 'Please enter your card number' },
                                        { pattern: /^[0-9]{16}$/, message: 'Card number must be 16 digits' },
                                    ]}>
                                    <Input className="form-control" placeholder="***888***9999765" maxLength={16} type="number" />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item name="exp_date" label="Expiry Date"
                                    rules={[{ required: true, message: 'Please enter the expiry date' }, { validator: validateExpiryDate }]}>
                                    <Input className="form-control" placeholder="MM/YY" type='month' />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item name="cvv" label="CVV"
                                    rules={[
                                        { required: true, message: 'Please enter the CVV' },
                                        { pattern: /^[0-9]{3}$/, message: 'CVV must be 3  digits' },
                                    ]}>
                                    <Input.Password className="form-control" placeholder="CVV" maxLength={3} />
                                </Form.Item>
                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="btn primaryButton text-white">Proceed to Pay</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>

            <Modal
                title="Benefits"
                open={isBenifitsOpen}
                onOk={hideBenifitsModal}
                onCancel={hideBenifitsModal}
                className="paymentModal"
                footer={false}
                width={680}
            >
                <div className="modalBody">
                    <ul className="benifitsList">
                        <li className="benifitsItems">
                            <b>Priority Listing:</b> Feature your property at the top of search results for maximum visibility.
                        </li>
                        <li className="benifitsItems">
                            <b>Unlimited Property Listings:</b> Post and manage as many properties as you want without any limits.
                        </li>
                        <li className="benifitsItems">
                            <b>Premium Marketing Tools:</b> Access advanced tools like virtual tours, video listings, and social media promotions.
                        </li>
                        <li className="benifitsItems">
                            <b>Dedicated Account Manager:</b> Receive personalized support and insights to help sell or rent your property faster.
                        </li>
                    </ul>
                </div>
            </Modal>
        </>
    );
};

export default SignUpPage;
