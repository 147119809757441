import React from "react";
import { Form, Input } from "antd";
import Map from "./map";

const LocalInfo = () => {

    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="locaInfoWrapper">
            <div className="innerWrapper">
                <div className="row">
                    <div className="col-md-6">
                        <Form
                            name="LocalInfoForm"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            layout="vertical"
                        >
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                    >
                                        <Input placeholder="Enter Name" className="form-control" />
                                    </Form.Item>
                                </div>
                                <div className="col-md-6">
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                    >
                                        <Input placeholder="Email Address" className="form-control" />
                                    </Form.Item>
                                </div>
                                <div className="col-md-12">
                                    <Form.Item
                                        label="Message"
                                        name="email"
                                    >
                                        <Input.TextArea 
                                            placeholder="Type here" 
                                            className="form-control" 
                                            rows={5}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-md-12 my-4">
                                    <button type="button" className="btn accountBtn w-auto">Submit</button>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="col-md-6">
                        <div className="mapWrapper">
                            <Map height={300}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LocalInfo