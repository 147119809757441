import React, { useEffect, useState } from "react";
import { SliderImageThree } from "../../constant/images";
import { message, Spin, Empty } from "antd";
import { TbBed, TbVector } from "react-icons/tb";
import { LuBath } from "react-icons/lu";
import { FaHeart, FaLocationDot } from "react-icons/fa6";
import { FaRegHeart } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import {
    useAddFavouriteMutation,
    useRecentlyViewPropertiesMutation,
    useRemoveFavouriteMutation,
    useSearchPropertyMutation,
} from "../../store/apiSlice";


const PropertyCard = ({ filterProperty, filterLoading, onPropertyCardData = () => {} }) => {

    const location = useLocation();
    const navigate = useNavigate();
    const [addViewProperty] = useRecentlyViewPropertiesMutation();
    const [addFavourite] = useAddFavouriteMutation();
    const [removeFavourite] = useRemoveFavouriteMutation();
    const [propertyList, setPropertyList] = useState();
    const [currentPage, setCurrentPage] = useState(location?.state?.currentPage);
    const [messageApi, contextHolder] = message.useMessage();
    const [lastPage, setLastPage] = useState();
    const [searchProperty,{isLoading:isSearchLoading}] = useSearchPropertyMutation()

    const success = (message) => {
        messageApi.open({
            type: "success",
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: "error",
            content: message,
        });
    };

    const toggleWishlist = async (id) => {
        let value = {
            property_id: id,
        };
        try {
            const res = await addFavourite(value);
            if (res.data) {
                const updatedListings = propertyList.map((listing) => 
                    listing.listing_key === id ? { ...listing, is_favorite: true } : listing
                );
                setPropertyList(updatedListings); 
                success("Added successfully");
            } else {
                errorMessage(res.error.data.message);
            }
        } catch {
            errorMessage("An unexpected error occurred");
        }
    };
    const deletefavourite = async (id) => {
        try {
            const res = await removeFavourite(id);
            if (res.data) {
                success(res.data.message);
                const updatedListings = propertyList.map((listing) => 
                    listing.listing_key === id ? { ...listing, is_favorite: false } : listing
                );
                setPropertyList(updatedListings);
              
            } else {
                errorMessage(res.error.data.message);
            }
        } catch {
            errorMessage("An unexpected error occurred");
        }
    };

    const viewProperty = async (data) => {
        let value = {
            property_id: data,
        };
        try {
            const res = await addViewProperty(value);
            if (res.data) {
                navigate("/property-details", { state: data });
            } else {
                errorMessage(res.error.data.message);
            } 
        } catch (error) {
            errorMessage("An unexpected error occurred");
        }
    };

    useEffect(() => {
        if (filterProperty?.data?.data?.length) {
                    setPropertyList(filterProperty?.data?.data);
        } else {
            setPropertyList([]);
        }
    }, [filterProperty]);

    const handleSearch = async()=>{ 
        let data = {}
    console.log(location?.state?.zipCode,'location?.state?.zipCode')
    if (location?.state?.zip_code) {
        data.zip_code = location?.state?.zip_code; 
    } else if (location?.state?.currentPage) {
        data.nearme = true; 
        data.current_page = location?.state?.currentPage; 
    } else if(location?.state?.zipCode){
        data.myzip = true
    }
            try{
                const res = await searchProperty(data) 
                setLastPage(res?.meta?.last_page)
                const { data: propertyData, error } = res?.data || {};

                if (propertyData) {
                   
                    onPropertyCardData({ propertyData, data });
                    setPropertyList(propertyData);
                } else if (error) {
                    setPropertyList([]);
                    errorMessage(error.message || "An error occurred");
                }
                          
            }catch(error){
                errorMessage("An unexpected error occurred");
            }    
    }
 useEffect(()=>{
    handleSearch()
 },[location?.state?.zip_code,location?.state?.currentPage])

 
  



    const handlePageChange = (page) => {

        if (page >= 1 && page <= lastPage) {
            setCurrentPage(page);
        }
    };

    return (
        <div className="propertyCardsContainer">
              { isSearchLoading || filterLoading ? (
            <Spin style={{ display: "flex", alignItems: "center", justifyContent: "center" }}  size="large"/>
        ) :propertyList?.length ? (
                propertyList?.map((property) => (
                    <div className="card propertyCard" key={property?.listing_key}>
                        <div className="card-body">
                            {contextHolder}
                            <div className="propertyContent">
                                <div className="propertyImage">
                                    <img
                                        src={SliderImageThree}
                                        className="img-fluid"
                                        alt="property"
                                    />
                                    <p className="wishlist">
                                        {property?.is_favorite?<FaHeart  onClick={() => deletefavourite(property?.listing_key)}/>:<FaRegHeart onClick={() => toggleWishlist(property?.listing_key)} />}
                                    </p>
                                </div>
                                <div className="propertyDetails">
                                    <h4 className="name">{ property?.unparsed_address}</h4>
                                    <p className="location">
                                        <FaLocationDot />{" "}
                                        {`${property?.postal_city}, ${property?.state_or_province}, ${property?.country}-${property?.postal_code}`}

                                    </p>
                                    <div className="amenities">
                                        <span className="beds">
                                            <TbBed /> { property?.bedrooms_total} Beds
                                        </span>
                                        <span className="bath">
                                            <LuBath /> {property?.bathrooms_total} Baths
                                        </span>
                                        <span className="bath">
                                            <TbVector /> {property?.lot_size} Sqft
                                        </span>
                                    </div>
                                    <div className="bottomDetails">
                                        <span className="dtl">{property?.property_type}</span>
                                        <span className="divider"></span>
                                        <span className="dtl">{property?.property_subtype}</span>
                                    </div>
                                </div>
                                <span className="divider"></span>
                                <div className="propertyPrice">
                                    <div className="price">
                                        <h4>${property?.price}</h4>
                                    </div>
                                    <div className="buttonWrap">
                                        <button
                                            type="button"
                                            className="btn detailBtn"
                                            onClick={() => viewProperty(property?.listing_key)}
                                        >
                                            Details
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className="emptyContainer">
                    <Empty />
                </div>
            )}
            {( propertyList?.length > 10) && (
                <>
                    <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                        Previous
                    </button>
                    <span> Page {currentPage} of {lastPage} </span>
                    <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === lastPage}>
                        Next
                    </button>
                </>
            )}
        </div>
    );
};

export default PropertyCard;
