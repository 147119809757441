import React, { useEffect } from "react";
import { Form, Input, message, Spin } from "antd";
import { useContactSupportMutation } from "../../store/apiSlice";
import { useGetUserDetailQuery } from "../../store/getSlice";
import { useSelector } from "react-redux";

const ContactForm = () => {
    const [form] = Form.useForm();
    const userData = useSelector((state) => state.persistedReducer);
    const isAuthenticated = userData.isAuthenticated;
    const [messageApi, contextHolder] = message.useMessage();
    const [addQuery] = useContactSupportMutation()
    const { data: userDetail ,refetch,isLoading} = useGetUserDetailQuery()
  
    const success = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };
    const onFinish = async (values) => {
        try {
            const res = await addQuery(values);
            if (res.data) {
                success('Added successfully');              
                if (isAuthenticated) {                
                    refetch();
                    form.resetFields(['message']);
                } else {                  
                    form.resetFields();
                }
            } else {
                errorMessage(res.error.data.message);
            }
        } catch (error) {
            errorMessage('An unexpected error occurred');
        }
    };
    

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    useEffect(() => {
        if (userDetail?.data && isAuthenticated) {
            form.setFieldsValue(userDetail.data);
        }
    }, [userDetail]);
    return (
        <>
        {contextHolder}
        {isLoading? <div className="spinContainer">
                    <Spin size="large" />
                </div>:
         <Form
         form={form}
            name="ContactFormForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
        >
            <div className="row">
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                        { required: true, message: 'Please enter your name' }
                       ]}
                >
                    <Input placeholder="Enter Name" className="form-control" />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        { required: true, message: 'Please enter your email' },
                        {
                            type: "email",
                            message:
                                "The input is not valid E-mail!",
                        },
                       ]}
                >
                    <Input placeholder="Email Address" className="form-control" />
                </Form.Item>
                {/* <Form.Item
                    label="Address"
                    name="address"
                >
                    <Input placeholder="Address" className="form-control" />
                </Form.Item> */}
                <Form.Item
                    label="Message"
                    name="message"
                    rules={[
                        { required: true, message: 'Please enter your message' }
                       ]}
                >
                    <Input.TextArea
                        placeholder="Type here"
                        className="form-control"
                        rows={5}
                    />
                </Form.Item>
                <div className="col-md-12 my-4">
                    <button type="submit" htmlType="submit" className="btn accountBtn w-auto">Submit</button>
                </div>
            </div>
        </Form>}
        </>
       
    );
};

export default ContactForm