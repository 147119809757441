import React from "react";
import { ArticleImage } from "../../constant/images"

const LatestArticles = () => {
    return(
        <div className="articlesWrapper mt-5">
            <div className="card articleCard">
                <div className="card-body">
                    <div className="cardHeader">
                        <h4 className="cardTitle">Latest Articles</h4>
                    </div>
                    <div className="cardContent">
                        <div className="card recenArticleCard">
                            <div className="innerBody">
                                <div className="imgSection">
                                    <img src={ArticleImage} className="img-fluid" alt="article" />
                                </div>
                                <div className="contentSection">
                                    <h4 className="articleName">Real Estate Tips for Home Buying & Selling</h4>
                                    <p className="articleDate">May 2, 2023</p>
                                </div>
                            </div>
                        </div>
                        <div className="card recenArticleCard">
                            <div className="innerBody">
                                <div className="imgSection">
                                    <img src={ArticleImage} className="img-fluid" alt="article" />
                                </div>
                                <div className="contentSection">
                                    <h4 className="articleName">Real Estate Tips for Home Buying & Selling</h4>
                                    <p className="articleDate">May 2, 2023</p>
                                </div>
                            </div>
                        </div>
                        <div className="card recenArticleCard">
                            <div className="innerBody">
                                <div className="imgSection">
                                    <img src={ArticleImage} className="img-fluid" alt="article" />
                                </div>
                                <div className="contentSection">
                                    <h4 className="articleName">Real Estate Tips for Home Buying & Selling</h4>
                                    <p className="articleDate">May 2, 2023</p>
                                </div>
                            </div>
                        </div>
                        <div className="card recenArticleCard">
                            <div className="innerBody">
                                <div className="imgSection">
                                    <img src={ArticleImage} className="img-fluid" alt="article" />
                                </div>
                                <div className="contentSection">
                                    <h4 className="articleName">Real Estate Tips for Home Buying & Selling</h4>
                                    <p className="articleDate">May 2, 2023</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LatestArticles;