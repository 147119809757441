import React, { useEffect, useState } from "react";
import { Input, Form, Select, Dropdown, Space, Row, Checkbox, message } from "antd";
import { useLocation } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { useSearchPropertyMutation } from "../../store/apiSlice";
import { useGetLocationQuery } from "../../store/getSlice";
import { google_Api_Key } from "../../Helper";
import Autocomplete from "react-google-autocomplete";

const { Option } = Select;

const propertyTypes = [
  { label: "Single Family Home", value: "single_family" },
  { label: "Townhouse/Condo", value: "townhouse_condo" },
  { label: "Multi-Family", value: "multi_family" },
  { label: "Commercial", value: "commercial" },
  { label: "Vacant Land", value: "vacant_land" },
  { label: "Mobile/Manufactured", value: "mobile_manufactured" },
  { label: "Unknown", value: "unknown" },
];

const DropdownContent = ({ setFilter }) => {
  const [form] = Form.useForm();
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const onFinish = async (values) => {
    let value = {
      square_feet: values?.square_feet,
      year_built: values?.year_built,
      property_type: values?.property_type,
    };

    setFilter(value);
  };

  const handleCheckboxChange = (label, checked) => {
    const updatedLabels = checked
      ? [...selectedLabels, label]
      : selectedLabels.filter((item) => item !== label);
    form.setFieldsValue({
      property_type: updatedLabels,
    });
    setSelectedLabels(updatedLabels);

    const values = form.getFieldsValue();
    onFinish(values);
  };
  const handlePriceChange = (allValues) => {
 
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const timeoutId = setTimeout(() => {
      onFinish(allValues, "formValues");
    }, 400);
    setTypingTimeout(timeoutId);
  };
  return (
    <div className="customDropdown">
      <h3 className="dropdownHeading">Property Type</h3>
      <Form
        form={form}
        name="moreFilter"
        layout="vertical"
       
        onValuesChange={(changedValues, allValues) => {
          if (changedValues.hasOwnProperty("square_feet") || changedValues.hasOwnProperty("year_built")) {
            handlePriceChange( allValues);
          } else {
            onFinish(allValues, "formValues");
          }
        }}
      >
        <div className="row">
          <div className="col-md-4">
            <div className="card dropDownCard">
              <div className="card-body">
                <Form.Item name="property_type">
                  {propertyTypes.map((type) => (
                    <Row key={type.value} style={{ marginBottom: "10px" }}>
                      <Checkbox
                        value={type.value}
                        onChange={(e) =>
                          handleCheckboxChange(type.label, e.target.checked)
                        }
                      >
                        {type.label}
                      </Checkbox>
                    </Row>
                  ))}
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="col-md-8">
            <div className="card dropDownCard">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <Form.Item
                      label="Min Square Feet"
                      className="form-control-check"
                      name="square_feet"
                    >
                      <Input
                        className="filter-control"
                        placeholder="Square Feet"
                      />
                    </Form.Item>
                    <Form.Item
                      label="SqFt. Price"
                      className="form-control-check"
                    >
                      <Select defaultValue="Any" disabled>
                        <Option value="any">Any</Option>
                        <Option value="500-1000">500 - 1000</Option>
                        <Option value="1000-2000">1000 - 2000</Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="col-md-6">
                    <Form.Item
                      label="Year Built"
                      className="form-control-check"
                      name="year_built"
                    >
                      <Input
                        className="filter-control"
                        placeholder="Year Built"
                      />
                    </Form.Item>

                    <Form.Item label="Lot Size">
                      <Select
                        defaultValue="Any"
                        className="form-control-check"
                        disabled
                      >
                        <Option value="any">Any</Option>
                        <Option value="small">Small</Option>
                        <Option value="medium">Medium</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

const Filters = ({ onFilter, propertyCardData }) => {
  const [form] = Form.useForm();
  const [filterProperty, { isLoading: filterLoading }] =
    useSearchPropertyMutation();
  const { data: getAddress } = useGetLocationQuery();
  const location = useLocation();
  const [data, setData] = useState();
  const [filter, setFilter] = useState({});
  const [sorting, setSorting] = useState({});
  const [count, setCount] = useState();
  const [loading, setLoading] = useState(true);
  const [zipCode,setZipCode] = useState()
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [addressDetails, setAddressDetails] = useState({
    zipCode: "",
    city: "",
    state: "",
    country: "",
  });
  const [messageApi, contextHolder] = message.useMessage();

  const errorMessage = (message) => {
      messageApi.open({
          type: "error",
          content: message,
      });
  };



  useEffect(() => {
    if (location?.state?.city) {
      setData(location?.state);
      setLoading(false);
    } else {
      setData(getAddress);
      setLoading(false);
    }
  }, [location?.state?.city, getAddress, addressDetails]);

  useEffect(() => {
    if (propertyCardData) {
      setLoading(false);
      setCount(propertyCardData);
    }
  }, [propertyCardData]);

  const onChangeSort = (value) => {
    let sort_order = "desc";
    let sort_criteria = "";
    switch (value.value) {
      case "3":
        sort_order = "asc";
        break;
      case "4":
        sort_order = "desc";
        break;
      case "5":
        sort_order = "asc";
        break;
      case "6":
        sort_order = "desc";
        break;
      default:
        sort_order = "asc";
    }
    switch (value.value) {
      case "1":
        sort_criteria = "beds";
        break;
      case "2":
        sort_criteria = "baths";
        break;
      case "3":
        sort_criteria = "price";
        break;
      case "4":
        sort_criteria = "price";
        break;
      case "5":
        sort_criteria = "lot_size";
        break;
      case "6":
        sort_criteria = "lot_size";
        break;
      case "7":
        sort_criteria = "year_built";
        break;
      default:
        sort_criteria = "";
    }
    let data = {
      sort_by: sort_criteria,
      sort_order: sort_order,
    };
    setSorting(data);
  };

  const onSearchSort = (value) => {
    console.log("search:", value);
  };
  const onFinish = async (value) => {
 
    let values = {};
    if (value.location) {
      values = {
        location:value.location,
        ...value,
        ...filter,
        ...sorting,
      };
    } else if (location?.state?.zip_code) {
      values = {
        zip_code: location?.state?.zip_code,
        ...value,
        ...filter,
        ...sorting,
      };
    } else if (location?.state?.currentPage) {
      values = {
        nearme: true,
        zip_code: data?.zip_code,
        current_page: location?.state?.currentPage,
        ...value,
        ...filter,
        ...sorting,
      };
    }
  
    try {
      const res = await filterProperty(values);

      if (res?.data) {
        setCount(res.data.data.length);
        onFilter({ data: res.data, filterLoading });
      } else {
        setCount(0);
        onFilter({ data: { data: [] }, filterLoading });
      }
    } catch (error) {
      console.error("Error filtering properties:", error);
    }
  };
  const handlePriceChange = (allValues) => {
 
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const timeoutId = setTimeout(() => {
      onFinish(allValues, "formValues");
    }, 400);
    setTypingTimeout(timeoutId);
  };
  useEffect(() => {
    if (Object.keys(filter).length > 0 || Object.keys(sorting).length > 0) {
      const formValues = form.getFieldsValue();
      console.log(filter,'formValues')
      onFinish(formValues);
    }
  }, [filter, sorting]);

  return (
    <div className="container">
        {contextHolder}
      <div className="row">
        <div className="col-lg-12">
          <div className="filterRow">
            <Form
              form={form}
              name="searchForm"
              layout="vertical"
              onValuesChange={(changedValues, allValues) => {
                if (changedValues.hasOwnProperty("min_price") || changedValues.hasOwnProperty("max_price")) {
                  handlePriceChange(changedValues, allValues);
                } else if (!changedValues.hasOwnProperty("location")) {
                  onFinish(allValues, "formValues");
                }
              }}
              autoComplete="off"
            >
              <Form.Item name="location">
                <Autocomplete
                  className="filter-control"
                  apiKey={google_Api_Key}
                  placeholder="Enter ZIP or location here"
                  onPlaceSelected={(place) => {
                    const addressComponents = place.address_components;
                    let searchzipCode = "";
                    let searchState = "";
                    let searchCity = "";
                    let searchCountry = "";
                    let formattedAddress = place.formatted_address;

                    addressComponents.forEach((component) => {
                      const types = component.types;
                      if (types.includes("postal_code")) {
                        searchzipCode = component.long_name;
                      }
                      if (types.includes("administrative_area_level_1")) {
                        searchState = component.long_name;
                      }

                      if (
                        types.includes("locality") ||
                        types.includes("postal_town")
                      ) {
                        searchCity = component.long_name;
                      }

                      if (types.includes("country")) {
                        searchCountry = component.long_name;
                      }
                     
                    });
                    form.setFieldsValue({ location: formattedAddress });
                    if (!searchzipCode || !searchCity || !searchState || !searchCountry) {
                        errorMessage("Please enter a valid location.");
                    }else{
                        onFinish({ location: searchzipCode });
                        setAddressDetails({
                            zipCode: searchzipCode,
                            city: searchCity,
                            state: searchState,
                            country: searchCountry,
                          });
                    }
                   
                  }}
                  style={{ width: "100%" }}
                  types={["geocode"]}
                  componentRestrictions={{ country: "US" }}
                />
              </Form.Item>

              <Form.Item name="beds">
                <Select
                  showSearch
                  placeholder="All Beds"
                  optionFilterProp="label"
                  className="filter-select"
                  options={[
                    { value: "", label: "All Beds" },
                    { value: "0", label: "0 Beds" },
                    { value: "1+", label: "1+ Beds" },
                    { value: "2+", label: "2+ Beds" },
                    { value: "3+", label: "3+ Beds" },
                    { value: "4+", label: "4+ Beds" },
                    { value: "5+", label: "5+ Beds" },
                  ]}
                />
              </Form.Item>

              <Form.Item name="baths">
                <Select
                  showSearch
                  placeholder="All Baths"
                  optionFilterProp="label"
                  className="filter-select"
                  options={[
                    { value: "", label: "All Bath" },
                    { value: "0+", label: "0+ Bath" },
                    { value: "1+", label: "1+ Baths" },
                    { value: "2+", label: "2+ Baths" },
                    { value: "3+", label: "3+ Baths" },
                  ]}
                />
              </Form.Item>

              <Form.Item className="priceWrap" name="min_price">
                <Input className="filter-control" placeholder="Min Price" />
              </Form.Item>

              <Form.Item className="priceWrap" name="max_price">
                <Input className="filter-control" placeholder="Max Price" />
              </Form.Item>

              <Form.Item className="moreFilter">
                <Dropdown
                  overlay={
                    <DropdownContent
                      count={count}
                      setCount={setCount}
                      onFilter={onFilter}
                      setFilter={setFilter}
                    />
                  }
                  trigger={["click"]}
                >
                  <Space>
                    More Filters
                    <DownOutlined />
                  </Space>
                </Dropdown>
              </Form.Item>

              {/* Optionally, you can add the search button back */}
              {/* <button type="submit" className="btn accountBtn">Search</button> */}
            </Form>
          </div>
          <div className="sortingRow">
            {location?.state?.zipCode?
          <h4 className="sortingText">
             {`We found ${count} according to your zipcode`}
            </h4>:
            <h4 className="sortingText">
              {addressDetails?.city ? count
                  ? `We found ${count} Listings in ${
                      addressDetails?.city ?? ""
                    }, ${addressDetails?.country ?? ""}`
                  : loading
                  ? "Loading..."
                  : `We found 0 Listings in ${addressDetails?.city ?? ""}, ${
                      addressDetails?.country ?? ""
                    }`
                : count
                ? `We found ${count} Listings in ${data?.city ?? ""}, ${
                    data?.country ?? ""
                  }`
                : loading
                ? "Loading..."
                : `We found 0 Listings in ${data?.city ?? ""}, ${
                    data?.country ?? ""
                  }`}
            </h4>}
            <div className="sortWrap">
              <Form.Item className="moreFilter" label="Sort by:" name="sort_by">
                <Select
                  showSearch
                  optionFilterProp="label"
                  onChange={onChangeSort}
                  labelInValue
                  onSearch={onSearchSort}
                  className="filter-sort"
                  defaultValue="Sort"
                  options={[
                    {
                      value: "1",
                      label: "Beds",
                    },
                    {
                      value: "2",
                      label: "Baths",
                    },
                    {
                      value: "3",
                      label: "Price (low to high)",
                    },
                    {
                      value: "4",
                      label: "Price (high to low)",
                    },
                    {
                      value: "5",
                      label: "Square Feet (low to high)",
                    },
                    {
                      value: "6",
                      label: "Square Feet (high to low)",
                    },
                    {
                      value: "7",
                      label: "Year Built (newest to oldest)",
                    },
                  ]}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
