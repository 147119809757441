import React from "react";
import { Avatar, message } from "antd";
import { useNavigate } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";

import { google_Api_Key } from "../../Helper";
import { useGetUserDetailQuery } from "../../store/getSlice";

const BannerSection = () => {
    const navigate = useNavigate();
    const {data:getUserDetail} = useGetUserDetailQuery()
    const [messageApi, contextHolder] = message.useMessage();

    const errorMessage = (message) => {
        messageApi.open({
            type: "error",
            content: message,
        });
    };

  

   

    return (
        <div className="bannerWrapper">
            <div className="container">
                {contextHolder}
                <div className="innerWrapper">
                    <div className="row">
                        <div className="col-lg-5 col-md-8">
                            <div className="bannerContent">
                                <h5 className="bannerTopTitle">Rainfrog</h5>
                                <h4 className="mainTitle">Find a perfect home you love..!</h4>
                                <p className="bannerSubTitle">
                                    Where Every Property Search Finds Its Perfect Match.
                                </p>
                                <div className="searchWrap">
                                  
                                    <Autocomplete
                                        apiKey={google_Api_Key}
                                        className="search-control"
                                        placeholder="Enter ZIP or location here"
                                        onPlaceSelected={(place) => {
                                            const addressComponents = place.address_components;
                                            let zip_code = "";
                                            let state = "";
                                            let city = "";
                                            let country = "";

                                            addressComponents.forEach((component) => {
                                                const types = component.types;

                                                if (types.includes("postal_code")) {
                                                    zip_code = component.long_name;

                                                }

                                                if (types.includes("administrative_area_level_1")) {
                                                    state = component.long_name;

                                                }

                                                if (
                                                    types.includes("locality") ||
                                                    types.includes("postal_town")
                                                ) {
                                                    city = component.long_name;

                                                }

                                                if (types.includes("country")) {
                                                    country = component.long_name;

                                                }
                                            });
                                            if (zip_code) {

                                                navigate("/search", {
                                                    state: {

                                                        city: city,
                                                        state: state,
                                                        country: country,
                                                        zip_code: zip_code,
                                                
                                                    },
                                                });

                                            }else{
                                               
                                                errorMessage("Please enter a valid location.");
                                              
                                            }
                                        }}
                                        style={{ width: "100%" }}
                                        options={{
                                            types: ["geocode"],
                                            componentRestrictions: { country: "US" },
                                        }}
                                    />
                                    <span className="divider"></span>
                                  
                                </div>
                                <div className="orSection">
                                    <p className="orText">Or</p>
                                </div>
                                <div className='d-flex justify-content-center '>
                                <div className="text-center">
                                    <button
                                        type="button"
                                        className="btn accountBtn"
                                        onClick={() => navigate("/search",{
                                            state: {
                                            currentPage:1
                                            },
                                        })}
                                    >
                                        Search Near Me
                                    </button>
                                </div>
                                <div className="text-center mx-4">
                                    <button
                                        type="button"
                                        className="btn accountBtn"
                                        onClick={() => navigate("/search",{
                                            state: {
                                            zipCode:getUserDetail?.data?.zips
                                            },
                                        })}
                                    >
                                        Search With ZipCode
                                    </button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
             

                    <div className="row bottomRow">
                        <div className="col-lg-3 col-md-6">
                            <div className="happyCustomerWrap">
                                <Avatar.Group
                                    size="large"
                                    max={{
                                        count: 4,
                                        style: {
                                            color: "#f56a00",
                                            backgroundColor: "#FFF",
                                            boxShadow: "10px 30px 50px 0px #0000000D",
                                        },
                                    }}
                                >
                                    <Avatar
                                        className="bannerAvatar"
                                        src="https://img.freepik.com/free-photo/bohemian-man-with-his-arms-crossed_1368-3542.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid"
                                    />
                                    <Avatar
                                        className="bannerAvatar"
                                        src="https://img.freepik.com/free-photo/confident-handsome-guy-posing-against-white-wall_176420-32936.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid"
                                    />
                                    <Avatar
                                        className="bannerAvatar"
                                        src="https://img.freepik.com/premium-photo/young-indian-businesswoman-employee-standing-white-background_1254992-196149.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid"
                                    />
                                    <Avatar
                                        className="bannerAvatar"
                                        src="https://img.freepik.com/free-photo/handsome-confident-smiling-man-with-hands-crossed-chest_176420-18743.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid"
                                    />
                                    <Avatar
                                        className="bannerAvatar"
                                        src="https://img.freepik.com/free-photo/close-up-toothy-smiling-brunet-man_1187-5800.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid"
                                    />
                                    <Avatar
                                        className="bannerAvatar"
                                        src="https://img.freepik.com/free-photo/indian-businessman-with-his-white-car_496169-2889.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid"
                                    />
                                    <Avatar
                                        className="bannerAvatar"
                                        src="https://img.freepik.com/free-photo/beautiful-male-half-length-portrait-isolated-white-studio-background-young-emotional-hindu-man-blue-shirt-facial-expression-human-emotions-advertising-concept-standing-smiling_155003-25250.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid"
                                    />
                                </Avatar.Group>
                                <span className="text">72k+ Happy Customers</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="listingrWrap">
                                <Avatar
                                    className="bannerAvatarVilla"
                                    src="https://img.freepik.com/free-photo/luxury-pool-villa-spectacular-contemporary-design-digital-art-real-estate-home-house-property-ge_1258-150759.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid"
                                />
                                <span className="text">
                                    200+ New <br />
                                    Listings Everyday!
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BannerSection;
