import React from "react";
import PageBanner from "./banner";
import CTA from "../../components/cta/cta";
import HelpSection from "./help";
import RecentSection from "./recentSection";
import FeaturedSection from "../../components/features/features";
import Filters from "./filters";
import PropertyCard from "./properyCard";


const PropertyListing = () => {

    return (
        <div className="propertyWrapper">
            <PageBanner />
            <div className="container">
                <div className="row">
                    <Filters />
                    <div className="col-lg-4 orderTwo">
                        <RecentSection />
                        <HelpSection />
                    </div>
                    <div className="col-lg-8 orderOne">
                        <PropertyCard />
                    </div>
                </div>
            </div>
            <FeaturedSection className="bgGrey" />
            <CTA />
        </div>
    );
};

export default PropertyListing;