import React from "react";
import { Form, Input, Select } from "antd";
import { Link } from "react-router-dom";

const RealEstate = () => {

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="realEstateWrapper">
            <div className="innerWrapper">
                <p className="topText">Need help finding a home?</p>
                <p className="subText">Contact a Real Estate Pro.</p>
                <div className="formWrapper">
                    <Form
                        name="RealEstateForm"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        layout="vertical"
                    >
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Item
                                    label="I’m looking to"
                                    name="looking"
                                >
                                    <Select className="form-control-check" onChange={handleChange} defaultValue={"Buy"}>
                                        <Select.Option value="buy">Buy</Select.Option>
                                        <Select.Option value="sell">Sell</Select.Option>
                                        <Select.Option value="rent">Rent</Select.Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <Form.Item
                                    label="Zip Code"
                                    name="zip"
                                >
                                    <Input placeholder="93505" className="form-control" />
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <Form.Item
                                    label="Time Frame"
                                    name="time_frame"
                                >
                                    <Select className="form-control-check" onChange={handleChange} defaultValue={"None"}>
                                        <Select.Option value="1">1</Select.Option>
                                        <Select.Option value="2">2</Select.Option>
                                        <Select.Option value="3">3</Select.Option>
                                        <Select.Option value="4">4</Select.Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <Form.Item
                                    label="First Name"
                                    name="fname"
                                >
                                    <Input placeholder="Enter first name" className="form-control" />
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <Form.Item
                                    label="Last Name"
                                    name="fname"
                                >
                                    <Input placeholder="Enter last name" className="form-control" />
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <Form.Item
                                    label="Email"
                                    name="email"
                                >
                                    <Input
                                        placeholder="Enter email here"
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <Form.Item
                                    label="Phone Number"
                                    name="phone"
                                >
                                    <Input
                                        placeholder="Enter phone here"
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <Form.Item
                                    label="Est. Max. Purchase Price"
                                    name="price"
                                >
                                    <Input
                                        placeholder="$34,550"
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <Form.Item
                                    label="Best Time to Contact"
                                    name="time"
                                >
                                    <Select className="form-control-check" onChange={handleChange} defaultValue={"None"}>
                                        <Select.Option value="1">1</Select.Option>
                                        <Select.Option value="2">2</Select.Option>
                                        <Select.Option value="3">3</Select.Option>
                                        <Select.Option value="4">4</Select.Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-12">
                                <div className="bottomText">
                                    <p>
                                        <strong>By clicking the button, you agree to</strong> <Link to={"/"} className="bottomLink">Terms and Conditions </Link>and consent to receive recurring informational and marketing calls and texts at the number you provided, including through autodialer with live, prerecorded and artificial voice, and email, from Move Sales, Inc., Opcity, Inc., realtor.com, and their networks of service providers. Msg or data rates may apply. This consent applies even if you are on a corporate, state or national Do Not Call List and is not a condition of any purchase.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12 text-center my-4">
                                <button type="button" className="btn accountBtn w-auto">Submit</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default RealEstate;