import React, { useState } from "react";
import { ResetPasswordImage, PopUpImage } from "../../constant/images";
import { Button, Form, Input, Modal, message } from 'antd';
import { Link} from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useResetPasswordMutation, useVerifyCodeMutation } from "../../store/loginRegisterSlice";
import { useSelector } from "react-redux";

const ResetPassword = () => {

    const [userResetPassword] = useResetPasswordMutation();
    const [verifyCode] = useVerifyCodeMutation()
    const email = useSelector((state) => state?.persistedReducer?.email?.email?.email)
    const [messageApi, contextHolder] = message.useMessage();
    const [showForm, setShowForm] = useState(true);
    const [code, setCode] = useState()
    const [isModalOpen, setIsModalOpen] = useState(false);


    const errorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const onSubmit = async (values) => {
        let data = {
            email: email,
            ...values
        }
        try {
            const response = await verifyCode(data);
            if (response.data.status) {
                setShowForm(false)
            }
        } catch (error) {
            errorMessage('Invalid or expired verification code')
        }

    };

    const onFinish = async (values) => {

        let data = {
            email: email,
            token: code,
            ...values
        }
        try {
            const response = await userResetPassword(data);
            if (response.data) {
                setIsModalOpen(true);
            } else {
                errorMessage(response.error.data.message);
            }
        } catch (error) {
            errorMessage('An unexpected error occurred')
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onChange = (text) => {
        setCode(text)
    };

    const sharedProps = {
        onChange,
    };

    return (
        <div className="authWrapper">
            <div className="innerWrapper">
                <div className="container">
                    {contextHolder}
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="card authCard forgotCard">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12 d-flex align-items-end justify-content-center">
                                            <div className="formWrapper">
                                                <SwitchTransition>
                                                    <CSSTransition
                                                        key={showForm ? "otpForm" : "newPasswordForm"}
                                                        timeout={300}
                                                        classNames="slide"
                                                    >
                                                        {showForm ? (
                                                            <div className="formSection otpForm">
                                                                <h4 className="authTitle">Reset Your Password</h4>
                                                                <p className="subTitle">Code Sent you, <span className="text-dark">{email}</span></p>
                                                                <div className="fomrWrap">
                                                                    <Form
                                                                        name="loginForm"
                                                                        layout="vertical"
                                                                        onFinish={onSubmit}
                                                                        onFinishFailed={onFinishFailed}
                                                                        autoComplete="off"
                                                                    >
                                                                        <Form.Item name="code" className="otp-control">
                                                                            <Input.OTP
                                                                                type="numeric"
                                                                                length={4}
                                                                                {...sharedProps}
                                                                                inputMode="numeric"
                                                                                onKeyPress={(e) => {
                                                                                    if (!/^[0-9]$/.test(e.key)) {
                                                                                        e.preventDefault();
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Form.Item>

                                                                        <Form.Item>
                                                                            <Button type="primary" htmlType="submit" className="primaryButton">
                                                                                Reset Password
                                                                            </Button>
                                                                        </Form.Item>
                                                                        <div className="resendRow">
                                                                            <p>
                                                                                Didn’t received the email? <Link to={'/'} className="linkText"> Click to resend</Link>.
                                                                            </p>
                                                                        </div>
                                                                        <div className="backLoginRow">
                                                                            <Link to={"/"} className="backLink">
                                                                                <IoIosArrowRoundBack /> Back to Log In
                                                                            </Link>
                                                                        </div>
                                                                    </Form>
                                                                </div>
                                                                <div className="bottomText">
                                                                    <p>
                                                                        Need more help? <Link to={'/'} className="linkText">Contact Us</Link>.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="formSection newPasswordForm">
                                                                <h4 className="authTitle">Reset Your Passowrd</h4>
                                                                <p className="subTitle">Code Sent you, <span className="text-dark">example@mail.com</span></p>
                                                                <div className="fomrWrap">
                                                                    <Form
                                                                        name="loginForm"
                                                                        layout="vertical"
                                                                        onFinish={onFinish}
                                                                        onFinishFailed={onFinishFailed}
                                                                        autoComplete="off"
                                                                    >
                                                                        <Form.Item
                                                                            name="password"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Please input your password!",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input.Password
                                                                                className="form-control"
                                                                                placeholder="New Password"
                                                                            />
                                                                        </Form.Item>

                                                                        <Form.Item
                                                                            name="password_confirmation"
                                                                            dependencies={["password"]}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Please input your Confirm password!",
                                                                                },
                                                                                ({ getFieldValue }) => ({
                                                                                    validator(rule, value) {
                                                                                        if (
                                                                                            !value ||
                                                                                            getFieldValue("password") === value
                                                                                        ) {
                                                                                            return Promise.resolve();
                                                                                        }
                                                                                        return Promise.reject(
                                                                                            "The passwords that you entered do not match!"
                                                                                        );
                                                                                    },
                                                                                }),
                                                                            ]}
                                                                        >
                                                                            <Input.Password
                                                                                className="form-control"
                                                                                placeholder="Confirm Password"
                                                                            />
                                                                        </Form.Item>

                                                                        <Form.Item>
                                                                            <Button type="primary" htmlType="submit" className="primaryButton">
                                                                                Reset Password
                                                                            </Button>
                                                                        </Form.Item>
                                                                        <div className="resendRow">
                                                                            <p>
                                                                                Didn’t received the email? <Link to={'/'} className="linkText"> Click to resend</Link>.
                                                                            </p>
                                                                        </div>
                                                                        <div className="backLoginRow">
                                                                            <Link to={"/"} className="backLink">
                                                                                <IoIosArrowRoundBack /> Back to Log In
                                                                            </Link>
                                                                        </div>
                                                                    </Form>
                                                                </div>
                                                                <div className="bottomText">
                                                                    <p>
                                                                        Need more help? <Link to={'/'} className="linkText">Contact Us</Link>.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </CSSTransition>
                                                </SwitchTransition>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 tabHide">
                                            <div className="rightSection">
                                                <img src={ResetPasswordImage} className="img-fluid" alt="login" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                className="confirmModal"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleOk}
                footer={false}
                centered
            >
                <div className="modalContent">
                    <div className="modalImage">
                        <img src={PopUpImage} className="img-fluid" alt="success" />
                    </div>
                    <div className="successMsg">
                        <h4 className="authTitle">Awesome!</h4>
                        <p className="subTitle">Your password has been reset.</p>
                        <div className="backLoginRow">
                            <Link to={"/login"} className="backLink">
                                <IoIosArrowRoundBack /> Back to Log In
                            </Link>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ResetPassword;