import React from 'react';
import { Navigate, Route, Routes} from "react-router-dom";
import { Layout } from 'antd';
import Login from '../pages/auth/login';
import SignUpPage from '../pages/auth/signUp';
import ForgotPassword from '../pages/auth/forgotPassword';
import ResetPassword from '../pages/auth/resetPassword';
import Footer from '../components/footer/footer';
import Header from '../components/header';
import Homepage from '../pages/home';
import PropertyListing from '../pages/property/listing';
import SearchProperties from '../pages/property/search';
import PropertyDetails from '../pages/property/propertyDetails';
import MyHomes from '../pages/property/favorites.js';
import MyAccount from '../pages/myAccount';

import { useSelector } from 'react-redux';
import TermsAndConditions from '../pages/other/terms.js';
import PrivacyPolicy from '../pages/other/privacy.js';
import ContactUs from '../pages/contact/contact.js';
import SingleBlog from '../pages/blogs/singleBlog.js';

const { Content } = Layout;

const AppLayout = ({ children }) => {
    const userData = useSelector((state) => state.persistedReducer);
    const isAuthenticated = userData?.isAuthenticated;

    return (
        <Layout className="bg-none">
            <Header />
            <Content>{children}</Content>
           {isAuthenticated?<Footer />:''} 
        </Layout>
    );
};

const AppRoute = () => {
    // const location = useLocation();
    // const noLayoutRoutes = ["/login", "/signup", "/forgot-password", "/reset-password"];
    const userData = useSelector((state) => state.persistedReducer);
    const isAuthenticated = userData.isAuthenticated;

    return (
        <Routes>
            {
                isAuthenticated ? (
                    
                    <>
                        <Route path="/" element={<Navigate to="/homepage" replace />} />
                        <Route path="/homepage" element={<AppLayout><Homepage /></AppLayout>} />
                        <Route path="/property-listing" element={<AppLayout><PropertyListing /></AppLayout>} />
                        <Route path="/property-details" element={<AppLayout><PropertyDetails /></AppLayout>} />
                        <Route path="/search" element={<AppLayout><SearchProperties /></AppLayout>} />
                        <Route path="/my-homes" element={<AppLayout><MyHomes /></AppLayout>} />
                        <Route path="/my-account" element={<AppLayout><MyAccount /></AppLayout>} />
                        <Route path="/terms-and-conditions" element={<AppLayout><TermsAndConditions /></AppLayout>} />
                        <Route path="/privacy-policy" element={<AppLayout><PrivacyPolicy /></AppLayout>} />
                        <Route path="/contact-us" element={<AppLayout><ContactUs /></AppLayout>} />
                        <Route path="/blog-details" element={<AppLayout><SingleBlog /></AppLayout>} />

                        {/* Redirect to homepage if trying to access login-related pages */}
                        <Route path="/login" element={<Navigate to="/homepage" replace />} />
                        <Route path="/signup" element={<Navigate to="/homepage" replace />} />
                        <Route path="/forgot-password" element={<Navigate to="/homepage" replace />} />
                        <Route path="/reset-password" element={<Navigate to="/homepage" replace />} />
                        
                    </>
                ) : (
                  
                    <>
                        <Route path="/login" element={<Login />} />
                        <Route path="/signup" element={<SignUpPage />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route path="/*" element={<Navigate to="/login" replace />} />
                        <Route path="/contact-us" element={<AppLayout><ContactUs /></AppLayout>} />
                    </>
                )
            }
        </Routes>
    );
};

export default AppRoute;
