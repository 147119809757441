import React from "react";
import { Avatar } from "antd";
import { ArticleImage } from "../../constant/images";
import { IoCalendarOutline } from "react-icons/io5";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";

const BlogDetails = () => {
    return(
        <div className="card detailsCard">
            <div className="card-body">
                <div className="blogImgSection">
                    <img src={ArticleImage} className="img-fluid" alt="article" />
                </div>
                <div className="authorDetailsSection">
                    <div className="name">
                        <Avatar
                            className="bannerAvatar"
                            src="https://img.freepik.com/free-photo/bohemian-man-with-his-arms-crossed_1368-3542.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid"
                        />
                        <h4 className="authorName">Walker</h4>
                    </div>
                    <div className="date">
                        <span className="icon"><IoCalendarOutline /></span>
                        Sep 07, 2024
                    </div>
                </div>
                <div className="blogContent">
                    <h3 className="blogTitle">Getting Started With Rent To Own Homes - Information About Buying A Home With A Lease Option</h3>
                    <p className="paragraph">Owning a home is a primary goal for most everyone. Nearly two-thirds of the population are home owners. Home ownership provides tangible benefits, such as shelter, privacy, a higher quality of life, and over time can provide a financial return on investment in the form of equity. Due to the amount of money required to buy a home, mortgage financing is required so buyers can pay off their purchase in small monthly increments, over an extended length of time.</p>
                    <p className="paragraph">Lending restrictions or personal situations may make it difficult for motivated buyers to obtain a mortgage and buy a home in a traditional fashion. Fortunately for these types buyers, the ability to find a home to rent with an option to buy provides an alternate path to home ownership.</p>
                    <p className="paragraph dFlex" style={{gap:' 16px'}}>
                        <img src={ArticleImage} className="img-fluid" alt="img" width={200} height={200} />
                        Owning a home is a primary goal for most everyone. Nearly two-thirds of the population are home owners. Home ownership provides tangible benefits, such as shelter, privacy, a higher quality of life, and over time can provide a financial return on investment in the form of equity. Due to the amount of money required to buy a home, mortgage financing is required so buyers can pay off their purchase in small monthly increments, over an extended length of time.</p>
                    <p className="paragraph">Owning a home is a primary goal for most everyone. Nearly two-thirds of the population are home owners. Home ownership provides tangible benefits, such as shelter, privacy, a higher quality of life, and over time can provide a financial return on investment in the form of equity. Due to the amount of money required to buy a home, mortgage financing is required so buyers can pay off their purchase in small monthly increments, over an extended length of time.</p>
                </div>
                <div className="navigation">
                    <button type="button" className="btn navigationBtn"><FaArrowLeft /> Previous</button>
                    <button type="button" className="btn navigationBtn">Next <FaArrowRight /></button>
                </div>
            </div>
        </div>
    );
};

export default BlogDetails;