import React from "react";

const HelpSection = () => {
    return(
        <div className="contactWrapper">
            <div className="innerWrap">
                <h4 className="helpTitle">Need help finding a home?</h4>
                <p className="helpSubText">Contact a Real Estate Pro.</p>
                <button type="button" className="btn accountBtn">Contact Now</button>
            </div>
        </div>
    );
}

export default HelpSection;