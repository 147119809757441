import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Logo } from "../../constant/images";
import { TbHomeCheck } from "react-icons/tb";
import { FaRegUserCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { logout } from "../../store/authSlice";
import { IoLogOut } from "react-icons/io5";
import { Dropdown, Space } from 'antd';

const Navbar = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleNavigate = () => {
        navigate('/my-homes');
    }

    const handleAccountNavigate = () => {
        navigate('/my-account');
    }

    const handleLogout = () => {
        dispatch(logout())
        localStorage.clear();
        navigate("/login");
    };

    return (
        <nav className="navbar navbar-expand-lg customNavbar">
            <div className="container">
                <Link className="navbar-brand mobileShow" to={"/"}>
                    <img src={Logo} className="img-fluid" alt="logo" />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to={"/"}>Home</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to={"/"}>Financing</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to={"/"} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Blogs
                            </Link>
                            <ul className="dropdown-menu blogDropdown">
                                <li><Link className="dropdown-item" to={"/"}>Capstone Realty</Link></li>
                                <li><Link className="dropdown-item" to={"/"}>HousingWire</Link></li>
                                <li><Link className="dropdown-item" to={"/"}>Bay Point Properties</Link></li>
                                <li><Link className="dropdown-item" to={"/"}>Harborview Realty Group</Link></li>
                                <li><Link className="dropdown-item" to={"/"}>Home Emporium</Link></li>
                            </ul>
                        </li> */}
                    </ul>
                    <Link className="navbar-brand mobileHide" to={"/"}>
                        <img src={Logo} className="img-fluid" alt="logo" />
                    </Link>
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <button type="button" className="btn headerBtn" onClick={() => handleNavigate()}>
                                <TbHomeCheck /> My Homes
                            </button>
                        </li>
                        <li className="nav-item">
                            <button type="button" className="btn accountBtn" onClick={() => handleAccountNavigate()}>
                                <FaRegUserCircle /> My Account
                            </button>
                        </li>
                        <li className="nav-item">
                            <button type="button" className="btn headerBtn" onClick={() => handleLogout()}>
                                <IoLogOut /> Log Out
                            </button>
                        </li>
                    </ul>
                </div>

            </div>
        </nav>
    );
};

export default Navbar;
