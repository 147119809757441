import React from "react";
import { Table } from "antd";

const MembershipInformation = () => {

    const columns = [
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Options',
            dataIndex: 'options',
            key: 'options',
            render: (options) => (
                <div className="buttonWrap">
                    {options}
                </div>
            ),
        },
    ];

    const dataSource = [
        {
            key: '1',
            description: 'Full access membership',
            status: 'Suspended',
            options: <button type="button" className="btn optionBtn">Cancel</button>,
        },
        {
            key: '2',
            description: 'The Foreclosures eBook',
            status: 'Purchased',
            options: <button type="button" className="btn optionBtn">Download</button>,
        },
    ];

    return (
        <div className="card accountCard">
            <div className="card-header">
                <h4 className="card-title">Membership Status</h4>
            </div>
            <div className="card-body">
                <div className="tableWrapper">
                    <Table
                        className="membershipTable"
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                    />
                </div>
            </div>
        </div>
    );
};

export default MembershipInformation;