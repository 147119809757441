import React, { useState } from "react";
import {
    Modal,
    Form,
    Input,
    Table,
    message,
    Button,
} from "antd";
import {
    useAddCardDetailsMutation,
    useDeleteCardDetailsMutation,
    useUpDateCardDetailsMutation,
} from "../../store/apiSlice";
import { useGetUserDetailQuery } from "../../store/getSlice";


const CreditCardInformation = () => {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [cardId, setCardId] = useState();
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [formType, setFormType] = useState();
    const [addCardDetails] = useAddCardDetailsMutation();
    const [upDateCardDetails] = useUpDateCardDetailsMutation();
    const [deleteCardDetails] = useDeleteCardDetailsMutation();
    const { data: userDetail, refetch } = useGetUserDetailQuery();
    const success = (message) => {
        messageApi.open({
            type: "success",
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: "error",
            content: message,
        });
    };
    const encryptedCardNumber = (cardNumber) => {
        if (typeof cardNumber !== 'string' || cardNumber.length < 4) {
          return 'Invalid card number';
        }
        return cardNumber.slice(0, -4).replace(/\d/g, '*') + cardNumber.slice(-4);
      };
    const showModal = (cardData) => {
        setIsModalOpen(true);
        if (cardData) {
            const newCardData = { ...cardData };

            if (newCardData.exp_date) {
                const [month, year] = newCardData.exp_date.split('/'); 
                const formattedExpDate = `20${year}-${month.padStart(2, '0')}`; 
                newCardData.exp_date = formattedExpDate;
            }
            if (newCardData.credit_card_number) {
                newCardData.credit_card_number = encryptedCardNumber(newCardData.credit_card_number);
              }
   
            form.setFieldsValue(newCardData); 
        } else {
            form.resetFields();
        }
    };
    

    const handleOk = () => {
        setIsModalOpen(false);
    };
    const validateExpiryDate = (_, value) => {
        if (!value) {
            return Promise.reject('Please enter the expiry date');
        }
    
        const date = new Date(value);
        const currentDate = new Date();
        const month = date.getMonth();
        const year = date.getFullYear();
    
        if (year < currentDate.getFullYear() || (year === currentDate.getFullYear() && month < currentDate.getMonth())) {
            return Promise.reject('The expiry date cannot be in the past');
        }
    
        return Promise.resolve();
    };
    const onFinish = async (values) => {
        const date = new Date(values.exp_date);
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
        const year = date.getFullYear().toString().slice(-2);
        const formattedDate = `${month}/${year}`;
        let data = {
            name_on_card: values.name_on_card,
            credit_card_number: values.credit_card_number,
            exp_date: formattedDate,
            cvv: values.cvv
        }
        if (formType === "Add") {
            try {
                const res = await addCardDetails(data);
                if (res.data) {
                    success("Address added succesfully");
                    refetch();
                    form.resetFields();
                    setIsModalOpen(false);
                } else {
                    errorMessage(res.error.data.message);
                }
            } catch (error) {
                errorMessage("An unexpected error occurred");
            }
        } else {
            try {
                const res = await upDateCardDetails({ data: data, cardId });
                if (res.data) {
                    success("Address updated succesfully");
                    refetch();
                    form.resetFields();
                    setIsModalOpen(false);
                } else {
                    errorMessage(res.error.data.message);
                }
            } catch (error) {
                errorMessage("An unexpected error occurred");
            }
        }
    };
    const handleConfirm = async () => {
        try {
            const res = await deleteCardDetails(cardId);
            if (res.data) {
                success(res.data.message);
                refetch();
                setIsDeleteModal(false);
            } else {
                errorMessage(res.error.data.message);
            }
        } catch (error) {
            errorMessage("An unexpected error occurred");
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };


    const columns = [
        {
            title: "Full Name",
            render: (data) => `${data?.name_on_card}`,
        },
        {
            title: "Card Number",
            render: (data) => encryptedCardNumber(data?.credit_card_number),
        },
        {
            title: "Expiration Date",
            render: (data) => `${data?.exp_date}`,
        },

        {
            title: "Options",
            render: (data) => (
                <div className="d-flex justify-content-evenly">
                    <div className="buttonWrap">
                        <button
                            type="button"
                            className="btn optionBtn"
                            onClick={() => {
                                showModal(data);
                                setCardId(data?.id);
                                setFormType("Edit");
                            }}
                        >
                            Edit
                        </button>
                    </div>
                    <div className="buttonWrap">
                        <button
                            type="button"
                            className="btn optionBtn"
                            onClick={() => {
                                setIsDeleteModal(true);
                                setCardId(data?.id);
                            }}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <div className="billingWrapper">
            <div className="card accountCard mt-4">
                {contextHolder}
                <div className="card-header">
                    <h4 className="card-title">Card Information</h4>
                    <button
                        type="button"
                        className="btn editButton"
                        onClick={() => {
                            showModal();
                            setFormType("Add");
                        }}
                    >
                        Add Card
                    </button>
                </div>
                {/* <div className="card-body">
                    <div className="billingWrap">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label>Full Name</label>
                                    <p className="userInfo">John Doe</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label>Card Number</label>
                                    <p className="userInfo">**** **** 1234</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label>Expiration Date</label>
                                    <p className="userInfo">03/2026</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="tableWrapper">
                    <Table
                        className="billingWrap"
                        dataSource={userDetail?.data?.cards}
                        columns={columns}
                        pagination={false}
                    />
                </div>
            </div>
            <Modal
                title={`${formType} Card Details`}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleOk}
                className="formCard"
                centered
                okText="Update"
                footer={null}
            >
                <div className="modalBody">
                    <Form
                    form={form}
                        name="cardForm"
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <div className="row">
                            <div className="col-md-12">
                                <Form.Item name="name_on_card" label="Full Name"  rules={[
                                    { required: true, message: 'Please enter the cardholder’s name' },
                                    {
                                        pattern: /^[A-Za-z\s]+$/, 
                                        message: 'Cardholder’s name can only contain letters and spaces',
                                    },
                                    {
                                        max: 25, 
                                        message: 'Cardholder’s name cannot exceed 25 characters',
                                    },
                                   ]}>
                                    <Input className="form-control" placeholder="Full Name" />
                                </Form.Item>
                            </div>
                            <div className="col-md-12">
                                <Form.Item name="credit_card_number" label="Card Number" rules={[
                                    { required: true, message: 'Please enter your card number' },
                                    { pattern: /^[0-9]{16}$/, message: 'Card number must be 16 digits' },
                                  ]}>
                                    <Input className="form-control" placeholder="Card Number" maxLength={16} />
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <Form.Item name="exp_date" label="Expiration Date"  rules={[{ required: true, message: 'Please enter the expiry date' },{ validator: validateExpiryDate }]}>
                                <Input className="form-control" placeholder="Expiration Date" type='month' style={{width:'114%'}} />
                                    {/* <Select className="form-control-check" onChange={handleChange} placeholder="Select Month">
                                        <Select.Option value="1">1</Select.Option>
                                        <Select.Option value="2">2</Select.Option>
                                        <Select.Option value="3">3</Select.Option>
                                        <Select.Option value="4">4</Select.Option>
                                        <Select.Option value="5">5</Select.Option>
                                        <Select.Option value="6">6</Select.Option>
                                        <Select.Option value="7">7</Select.Option>
                                        <Select.Option value="8">8</Select.Option>
                                        <Select.Option value="9">9</Select.Option>
                                        <Select.Option value="10">10</Select.Option>
                                        <Select.Option value="11">11</Select.Option>
                                        <Select.Option value="12">12</Select.Option>
                                    </Select> */}
                                </Form.Item>
                            </div>
                            {/* <div className="col-md-4">
                                <Form.Item
                                    name="year"
                                    label="Year"
                                >
                                    <Select className="form-control-check" onChange={handleChange} placeholder="Select Year">
                                        <Select.Option value="2024">2024</Select.Option>
                                        <Select.Option value="2025">2025</Select.Option>
                                        <Select.Option value="2026">2026</Select.Option>
                                        <Select.Option value="2027">2027</Select.Option>
                                        <Select.Option value="2028">2028</Select.Option>
                                        <Select.Option value="2029">2029</Select.Option>
                                        <Select.Option value="2030">2030</Select.Option>
                                        <Select.Option value="2031">2031</Select.Option>
                                        <Select.Option value="2032">2032</Select.Option>
                                        <Select.Option value="2033">2033</Select.Option>
                                        <Select.Option value="2034">2034</Select.Option>
                                        <Select.Option value="2035">2035</Select.Option>
                                        <Select.Option value="2036">2036</Select.Option>
                                    </Select>
                                </Form.Item>
                            </div> */}
                            <div className="col-md-4">
                                <Form.Item name="cvv" label="CVV" rules={[
                                    { required: true, message: 'Please enter the CVV' },
                                    { pattern: /^[0-9]{3}$/, message: 'CVV must be 3  digits' },
                                  ]}>
                                    <Input.Password className="form-control" placeholder="CVV" maxLength={3}  />
                                   
                                </Form.Item>
                            </div>
                            <div className="buttonWrapper">
                                <Button htmlType="submit" className="submitBtn me-4">
                                    Save
                                </Button>
                                <button
                                    type="button"
                                    className="btn cancelBtn"
                                    onClick={handleOk}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>
            <Modal
                title="Confirm"
                open={isDeleteModal}
                onOk={handleConfirm}
                onCancel={() => setIsDeleteModal(false)}
            >
                <h5>Are you sure want to delete the card detail?</h5>
            </Modal>
        </div>
    );
};

export default CreditCardInformation;
