import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAuthenticated: false,
    user: null,
    email:null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess: (state, action) => {
            state.isAuthenticated = true;
            state.user = action.payload;
        },
        saveEmail: (state, action) => {
            state.email = action.payload;
          },
          logout: (state) => {
            state.isAuthenticated = false;
            state.user = null;
          },

    },
});

 export const { loginSuccess,saveEmail,logout } = authSlice.actions;
export default authSlice.reducer;
