import React from "react";
import { Row, Col, Image } from "antd";
import { FaCamera, FaLocationDot } from "react-icons/fa6";
import { IoShareSocial } from "react-icons/io5";

const Gallery = () => {
    return (
        <div className="galleryWrapper">
            <div className="innerWrapper">
                <Row className="h-100">
                    <Col span={14}>
                        <div className="galleryImage">
                        <Image src="https://img.freepik.com/free-photo/luxury-pool-villa-spectacular-contemporary-design-digital-art-real-estate-home-house-property-ge_1258-150755.jpg?t=st=1725302243~exp=1725305843~hmac=1eaf198af2cb456e58b4a33a249ab9a15cb556a7fd044ad198ef275f1bfec118&w=1380" className="img-fluid" alt="property" />
                        </div>
                    </Col>
                    <Col span={10}>
                        <Row className="h-100">
                            <Col span={12}>
                                <div className="galleryImage">
                                    <Image src="https://img.freepik.com/free-photo/lobby-living-room-hotel_1150-11124.jpg?t=st=1725338479~exp=1725342079~hmac=9e2d12aa4b1f8ec0ef2b3af8ddc4eed55a7ccf326c29cfd5c296190b6fe86e9a&w=1380" className="img-fluid" alt="property" />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="galleryImage">
                                    <Image src="https://img.freepik.com/free-photo/living-room-modern-apartment-with-two-identical-sofas-opposite-each-other_181624-14768.jpg?t=st=1725338390~exp=1725341990~hmac=31d633d83e4961ff1c4083441d88774e1eb941766202fa25ca5e0e4191fc979e&w=1380" className="img-fluid" alt="property" />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="galleryImage">
                                    <Image src="https://img.freepik.com/free-photo/3d-rendering-modern-dining-room-living-room-with-luxury-decor_105762-1934.jpg?t=st=1725338538~exp=1725342138~hmac=c0ae4cafd243f8c31a42645431a4636600ce30b41f2375f358ef3f5c4cc6805f&w=1380" className="img-fluid" alt="property" />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="galleryImage">
                                    <Image src="https://img.freepik.com/free-photo/3d-rendering-modern-dining-room-living-room-with-luxury-decor_105762-2256.jpg?t=st=1725338629~exp=1725342229~hmac=029154388cbefc20a5a8c4e541b96621bb3ce5391ed2c595fb7899309e47e4f4&w=1380" className="img-fluid" alt="property" />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="galleryImage">
                                    <Image src="https://img.freepik.com/free-photo/3d-rendering-beautiful-luxury-bedroom-suite-hotel-with-tv-shelf_105762-2077.jpg?t=st=1725338334~exp=1725341934~hmac=d270fba984f55ce139f240bb5c678b2f200eff09212cdf1822131a8eed14981f&w=1380" className="img-fluid" alt="property" />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="galleryImage">
                                    <Image src="https://img.freepik.com/free-photo/3d-electric-car-building_23-2148972401.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid" className="img-fluid" alt="property" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="bottomRow">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="buttonWrap">
                                    <button type="button" className="btn photoBtn">
                                        <FaCamera /> View Photos
                                    </button>
                                </div>
                            </div>
                            <div className="col">
                                <div className="buttonsWrap">
                                    <button type="button" className="btn iconBtn me-4">
                                        <IoShareSocial />
                                    </button>
                                    <button type="button" className="btn iconBtn">
                                        <FaLocationDot />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Gallery;