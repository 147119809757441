import React, { useState } from "react";
import { Collapse, Table } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { BiTransferAlt } from "react-icons/bi";
import { MdOutlineFileDownload } from "react-icons/md";

const { Panel } = Collapse;

const TransactionSummary = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 5;

    const dataSource = [
        {
            key: '1',
            id: 'ACRAF23DB3C4',
            date: '07 Sep, 2024',
            number: '***5',
            plan: 'Gold',
            amount: '$1500',
            interval: 'Monthly',
        },
        {
            key: '2',
            id: 'ACRAF23DB3C4',
            date: '07 Sep, 2024',
            number: '***5',
            plan: 'Gold',
            amount: '$1500',
            interval: 'Monthly',
        },
        {
            key: '3',
            id: 'ACRAF23DB3C4',
            date: '07 Sep, 2024',
            number: '***5',
            plan: 'Gold',
            amount: '$1500',
            interval: 'Monthly',
        },
        {
            key: '4',
            id: 'ACRAF23DB3C4',
            date: '07 Sep, 2024',
            number: '***5',
            plan: 'Gold',
            amount: '$1500',
            interval: 'Monthly',
        },
        {
            key: '5',
            id: 'ACRAF23DB3C4',
            date: '07 Sep, 2024',
            number: '***5',
            plan: 'Gold',
            amount: '$1500',
            interval: 'Monthly',
        },
        {
            key: '6',
            id: 'ACRAF23DB3C4',
            date: '07 Sep, 2024',
            number: '***5',
            plan: 'Gold',
            amount: '$1500',
            interval: 'Monthly',
        },
        {
            key: '7',
            id: 'ACRAF23DB3C4',
            date: '07 Sep, 2024',
            number: '***5',
            plan: 'Gold',
            amount: '$1500',
            interval: 'Monthly',
        },
    ];

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'serial',
            key: 'serial',
            render: (text, record, index) => {
                const startSerial = (currentPage - 1) * pageSize + 1;
                return startSerial + index;
            },
        },
        {
            title: 'Transaction ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Card Number',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: 'Membership Plan',
            dataIndex: 'plan',
            key: 'plan',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Recurring Interval',
            dataIndex: 'interval',
            key: 'interval',
        },
        {
            title: 'Download Invoice',
            dataIndex: 'invoice',
            key: 'invoice',
            render: (text, record) => (
                <button type="button" className="btn downloadBtn" onClick={() => console.log('Download')}><MdOutlineFileDownload /></button>
            ),
        },
    ];

    return (
        <div className="transactionWrapper">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="sectionHeader">
                            <h3 className="sectionHeading">Transaction Summary</h3>
                        </div>
                        <div className="sectionContent">
                            <Collapse
                                accordion
                                ghost
                                bordered={false}
                                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? -90 : 90} />}
                                expandIconPosition='end'
                                className="transactionCollapse"
                                defaultActiveKey={[1]}
                            >
                                <Panel header={<p className="panelHeader"><BiTransferAlt /> View Transactions</p>} key="1">
                                    <div className="tableWrapper">
                                        <Table
                                            className="transactionTable"
                                            dataSource={dataSource} 
                                            columns={columns} 
                                            pagination={{
                                                pageSize,
                                                current: currentPage,
                                                onChange: page => setCurrentPage(page),
                                            }}
                                        />
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionSummary;