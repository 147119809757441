import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { fetchDetail_Url } from "../Helper";

const getToken = async () => {
    // Simulate an asynchronous token fetch
    return new Promise((resolve) => {
        setTimeout(() => {
            const persistedRoot = localStorage?.getItem("persist:root");
            if (!persistedRoot) return null;
            const persistedRootObj = JSON.parse(persistedRoot);
            const userObj = persistedRootObj?.user
                ? JSON.parse(persistedRootObj.user)
                : null;
            resolve(userObj?.user?.token); // Replace with actual token fetching logic
            return userObj?.user?.token || null;
        }, 500);
    });
};

export const getApi = createApi({
    reducerPath: "getApi",
    baseQuery: fetchBaseQuery({
        baseUrl: fetchDetail_Url,
        prepareHeaders: async (headers) => {
            const token = await getToken();
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            headers.set("Content-Type", "application/json; charset=UTF-8");
            headers.set("Accept", "application/json");
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getNearProperty: builder.query({
            query: (page) => {
                if (page)
                    return {
                        url: `get-property-near-me?page=${page}`,
                        method: "GET",
                    }
            },
            providesTags: ["nearProperty"],
        }),
        getRecentlyProperty: builder.query({
            query: () => ({
                url: "recently-viewed-properties",
                method: "GET",
            }),
            providesTags: ["RecentlyProperty"],
        }),
        favouriteProperty: builder.query({
            query: () => ({
                url: "favorite_property",
                method: "GET",
            }),
            providesTags: ["FavouriteProperty"],
        }),
        getUserDetail: builder.query({
            query: () => ({
                url: "user-detail",
                method: "GET",
            }),
            providesTags: ["UserDetail"],
        }),
        getPropertyDetails : builder.query({
            query: (id) => ({
                url:`property/${id}`,
                method: "GET",
            }),
            providesTags: ["PropertyDetails"],
        }),
        getLocation : builder.query({
            query: () => ({
                url:`get-ip`,
                method: "GET",
            }),
            providesTags: ["IpAddress"],
        }),
        getRegisterUserDetails: builder.query({
            query: (token) => {
                if (token) {
                    return {
                        url: `user-info/${token}`,
                        method: "GET"
                    };
                }
               
            },
            providesTags: ["RegisterUserDetails"],
        }),
        getPagesDetail : builder.query({
            query: (page) => {
                if (page) {
                    return {
                        url: `pages/${page}`,
                        method: "GET"
                    };
                }               
            },
            providesTags: ["PagesDetails"],
        }),
        getTransaction : builder.query({
            query: () => ({
                url:`transactions`,
                method: "GET",
            }),
            providesTags: ["Transaction"],
        }),


    }),

    tagTypes: ["nearProperty", "RecentlyProperty", "FavouriteProperty", "UserDetail","PropertyDetails","IpAddress","RegisterUserDetails","PagesDetails","Transaction"]
});


export const { useGetNearPropertyQuery, useGetRecentlyPropertyQuery, useFavouritePropertyQuery, useGetUserDetailQuery,useGetPropertyDetailsQuery,useGetLocationQuery,useGetRegisterUserDetailsQuery,useGetPagesDetailQuery,useGetTransactionQuery } = getApi;
