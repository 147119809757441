import React from "react";

const Banner = () => {
    return (
        <div className="bannerWrapper">
            <div className="innerWrapper">
                <div className="heroText">
                    <div className="bannerContent">
                        <h2 className="heroText">
                            Find Your Perfect Property Match
                        </h2>
                        <p className="heroSubText">
                            The perfect match is just a scroll away
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Banner;