import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { fetchDetail_Url } from "../Helper";

const getToken = () => {
  const persistedRoot = localStorage?.getItem("persist:root");
  if (!persistedRoot) return null;
  const persistedRootObj = JSON.parse(persistedRoot);
  const userObj = persistedRootObj?.user
    ? JSON.parse(persistedRootObj.user)
    : null;
  if (userObj !== undefined && userObj !== null) {
    localStorage.setItem("Token", userObj?.user?.token);
    return userObj?.user?.token || null;
  }
};

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: fetchDetail_Url,
    prepareHeaders: (headers) => {
      const token = getToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json; charset=UTF-8");
      headers.set("Accept", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    showProperties: builder.mutation({
      query: (data) => ({
        url: "properties",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Properties"],
    }),
    recentlyViewProperties: builder.mutation({
      query: (data) => ({
        url: "recently-viewed-property/add",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ViewProperties"],
    }),
    searchProperty: builder.mutation({
      query: (data) => {
        console.log(data,'data')
        const params = new URLSearchParams();
        if (data?.myzip) params.append('myzip', data.myzip);
        if (data?.nearme) params.append('nearme', data.nearme);
        if (data?.current_page) params.append('current_page', data.current_page);
        if (data?.zip_code) params.append('zip_code', data.zip_code);
        if (data?.beds) params.append('beds', data.beds);
        if (data?.baths) params.append('baths', data.baths);
        if (data?.min_price) params.append('min_price', data.min_price);
        if (data?.max_price) params.append('max_price', data.max_price);
        if (data?.location) params.append('location', data.location);
        if (data?.square_feet) params.append('square_feet', data.square_feet);
        if (data?.year_built) params.append('year_built', data.year_built);
        if (data?.property_type?.length) {
            params.append('property_type', data.property_type.join(','));
        }  
        if (data?.sort_by) params.append('sort_by', data.sort_by);
        if (data?.sort_order) params.append('sort_order', data.sort_order);

        return {
            url: `search-property?${params.toString()}`, 
            method: "POST",
        };
    },
    }),
    addFavourite: builder.mutation({
      query: (data) => ({
        url: "favorite_property/add",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AddFavourite"],
    }),
    removeFavourite: builder.mutation({
      query: (data) => ({
        url: `favorite_property/delete/${data}`,
        method: "DELETE",

      }),
      invalidatesTags: ["RemoveFavourite"],
    }),
    updateProfile: builder.mutation({
      query: (data) => ({
        url: `update-user`,
        method: "PUT",
        body: data

      }),
      invalidatesTags: ["UpdateProfile"],
    }),
    changePassword: builder.mutation({
      query: (password) => ({
        url: "update-password",
        method: "PUT",
        body: password,
      }),
      invalidatesTags: ["ChangePassword"],
    }),
    filterProperty: builder.mutation({
      query: (data) => ({
        url: "filter-property",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["FilterProperty"],
    }),
    contactSupport: builder.mutation({
      query: (data) => ({
        url: "contact_support/add",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ContactUs"],
    }),
    addCardDetails: builder.mutation({
      query: (data) => ({
        url: "card/add",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AddCardDetails"],
    }),
    upDateCardDetails: builder.mutation({
      query: ({ data, cardId }) => ({
        url: `card/update/${cardId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["UpdateCardDetails"],
    }),
    deleteCardDetails: builder.mutation({
      query: (id) => ({
        url: `card/delete/${id}`,
        method: "DELETE",

      }),
      invalidatesTags: ["DeleteCardDetails"],
    }),
    addAddressDetails: builder.mutation({
      query: (data) => ({
        url: "billing_address",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AddAddress"],
    }),
    upDateAddressDetails: builder.mutation({
      query: ({ data, addressId }) => ({
        url: `billing_address/${addressId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["UpdateAddressDetails"],
    }),
    deleteAddressDetails: builder.mutation({
      query: (id) => ({
        url: `billing_address/${id}`,
        method: "DELETE",

      }),
      invalidatesTags: ["DeleteAddressDetails"],
    }),
  }),

  tagTypes: ["Properties", "ViewProperties", "SearchProperty", "AddFavourite", "RemoveFavourite", "UpdateProfile", "ChangePassword", "FilterProperty", "ContactUs", "AddCardDetails", "UpdateCardDetails", "DeleteCardDetails", "AddAddress", "UpdateAddressDetails", "DeleteAddressDetails"]
});

export const { useShowPropertiesMutation, useRecentlyViewPropertiesMutation, useSearchPropertyMutation, useAddFavouriteMutation, useRemoveFavouriteMutation, useUpdateProfileMutation, useChangePasswordMutation, useFilterPropertyMutation, useContactSupportMutation, useAddCardDetailsMutation, useDeleteCardDetailsMutation, useUpDateCardDetailsMutation, useAddAddressDetailsMutation, useUpDateAddressDetailsMutation, useDeleteAddressDetailsMutation } = apiSlice;
