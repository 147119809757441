import React from "react";
import HelpSection from "../property/help";
import CTA from "../../components/cta/cta";
import LatestArticles from "./latestArticles";
import BlogDetails from "./blogDetails";
import EbookSection from "./ebook";

const SingleBlog = () => {
    return(
        <div className="singleBlogWrapper">
            <div className="pageHeader">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="pageHeading">
                                <h3>Rent to Own Overview</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="innerWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8">
                            <BlogDetails />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <HelpSection />
                            <LatestArticles />
                        </div>
                    </div>
                </div>
            </div>
            <EbookSection className={'bgGrey ebookWrapper'}/>
            <CTA />
        </div>
    );
};

export default SingleBlog;