import { BrowserRouter } from "react-router-dom";
import AppRoute from "./routes/routes";
import "./styles/styles.scss";

function App() {
    return (
        <BrowserRouter>
            <AppRoute />
        </BrowserRouter>
    );
}

export default App;
