import React, { useState } from "react";

import { Form, Input, Select } from "antd";

import { Link } from "react-router-dom";

import Highcharts from "highcharts";

import HighchartsReact from "highcharts-react-official";

const CalculatePayment = () => {
    const [monthlyPayment, setMonthlyPayment] = useState(null);
    const [mortgagecalculation, setMortgageCalculation] = useState(null);
    const [purchasePrice, setPurchasePrice] = useState(0);
    const [downPayment, setDownPayment] = useState(0);
    const [downPaymentPercent, setDownPaymentPercent] = useState(0);

    const calculateDownPaymentPercent = (dp, price) => {
        if (price && dp) {
            const percent = ((dp / price) * 100).toFixed(2);
            setDownPaymentPercent(percent);
        } else {
            setDownPaymentPercent(0);
        }
    };

    const onPriceChange = (e) => {
        const price = parseFloat(e.target.value);
        setPurchasePrice(price);
    };

    const onDownPaymentChange = (e) => {
        const dp = parseFloat(e.target.value);
        setDownPayment(dp);
        calculateDownPaymentPercent(dp, purchasePrice);
    };

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const calculateMortgage = (values) => {
        const purchasePrice = parseFloat(values.price);

        const downPayment = parseFloat(values.dp);

        const loanAmount = purchasePrice - downPayment;

        const loanTerm = parseInt(values.loan_type);

        const monthlyInterestRate = values.annualInterestRate / 100 / 12;

        const numberOfPayments = loanTerm * 12;

        // Mortgage calculation formula

        const monthlyPayment =
            (loanAmount *
                monthlyInterestRate *
                Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
            (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);

        const totalPayableInterest = (monthlyPayment * numberOfPayments) - loanAmount;

        const firstMonthInterest = loanAmount * monthlyInterestRate;
        const firstMonthPrincipal = monthlyPayment - firstMonthInterest;

        setMonthlyPayment({
            total: monthlyPayment.toFixed(2),

            principal: firstMonthPrincipal.toFixed(2),

            interest: firstMonthInterest.toFixed(2),
        });
        setMortgageCalculation({
            total: (monthlyPayment * numberOfPayments).toFixed(2),

            principal: loanAmount.toFixed(2),

            interest: totalPayableInterest.toFixed(2),
        })
    };

    const onFinish = (values) => {
        calculateMortgage(values);
    };

    const chartOptions = {
        chart: {
            type: "pie",
        },

        title: {
            text: "Principal vs Interest Breakdown",
        },

        plotOptions: {
            pie: {
                innerSize: "70%",
                dataLabels: {
                    enabled: true,
                    format: "{point.name}: ${point.y:.1f}",
                },
            },
        },

        series: [
            {
                name: "Payment Breakdown",
                data: [
                    {
                        name: "Principal",
                        y: mortgagecalculation ? (parseFloat(mortgagecalculation.principal)) : 0,
                    },

                    {
                        name: "Interest",
                        y: mortgagecalculation ? parseFloat(mortgagecalculation.interest) : 0,
                    },
                ],
            },
        ],
    };

    return (
        <div className="calculateWrapper">
            <div className="innerWrap">
                <p className="topText">Mortgage Calculator</p>

                <p className="subText">
                    For traditional mortgage, enter your home loan details and get
                    personalized quotes with today’s mortgage rates.
                </p>

                <div className="formWrapper">
                    <Form
                        name="PaymentForm"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        layout="vertical"
                    >
                        <div className="row">
                            {/* <div className="col-md-3">
                                <Form.Item label="Loan Purpose" name="purpose">
                                    <Select
                                        className="form-control-check"
                                        onChange={handleChange}
                                        defaultValue={"Purchase"}
                                    >
                                        <Select.Option value="purchase">Purchase</Select.Option>

                                        <Select.Option value="refinance">Refinance</Select.Option>
                                    </Select>
                                </Form.Item>
                            </div> */}

                            <div className="col-md-3">
                                <Form.Item
                                    label="Purchase Price"
                                    name="price"
                                    rules={[
                                        {
                                            required: true,

                                            message: "Please input your purchase price",
                                        },
                                    ]}
                                >
                                    <Input placeholder="$ 205,900" className="form-control"  onChange={onPriceChange} />
                                </Form.Item>
                            </div>

                            <div className="col-md-3">
                                <Form.Item
                                    label="Down Payment"
                                    name="dp"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your down payment",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="$ 205,900"
                                        className="form-control-addon"
                                        addonAfter={`${downPaymentPercent}%`}
                                        onChange={onDownPaymentChange}
                                    />
                                </Form.Item>
                            </div>

                            <div className="col-md-3">
                                <Form.Item
                                    label="Loan Tenure"
                                    name="loan_type"
                                    rules={[
                                        {
                                            required: true,

                                            message: "Select the loan tenure",
                                        },
                                    ]}
                                >
                                    <Select
                                        className="form-control-check"
                                        onChange={handleChange}
                                        defaultValue={"30 Year Fixed"}
                                    >
                                        <Select.Option value="10">10 Year Fixed</Select.Option>

                                        <Select.Option value="20">20 Year Fixed</Select.Option>

                                        <Select.Option value="30">30 Year Fixed</Select.Option>
                                    </Select>
                                </Form.Item>
                            </div>

                            {/* <div className="col-md-3">
                                <Form.Item label="Zip" name="zip">
                                    <Input placeholder="83505" className="form-control" />
                                </Form.Item>
                            </div> */}

                            {/* <div className="col-md-3">
                                <Form.Item label="Credit Score" name="score">
                                    <Select
                                        className="form-control-check"
                                        onChange={handleChange}
                                        defaultValue={"Excellent (740-850)"}
                                    >
                                        <Select.Option value="1">Excellent (740-850)</Select.Option>

                                        <Select.Option value="2">Very Good (720-739)</Select.Option>

                                        <Select.Option value="3">Good (700-719)</Select.Option>

                                        <Select.Option value="4">
                                            Above Average (680-699)
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </div> */}

                            {/* <div className="col-md-3">
                                <Form.Item label="Points" name="points">
                                    <Select
                                        className="form-control-check"
                                        onChange={handleChange}
                                        defaultValue={"Up to 0.5 points"}
                                    >
                                        <Select.Option value="1">0 Points</Select.Option>

                                        <Select.Option value="2">Up to 0.5 points</Select.Option>

                                        <Select.Option value="3">Up to 1 point</Select.Option>

                                        <Select.Option value="4">Up to 1.5 points</Select.Option>

                                        <Select.Option value="4">Up to 2 points</Select.Option>

                                        <Select.Option value="4">Up to 2.5 points</Select.Option>

                                        <Select.Option value="4">Up to 3 points</Select.Option>
                                    </Select>
                                </Form.Item>
                            </div> */}

                            {/* <div className="col-md-3">
                                <Form.Item
                                    label="Rate Lock"
                                    name="rate"
                                    rules={[
                                        {
                                            required: true,

                                            message: "Select the rate lock",
                                        },
                                    ]}
                                >
                                    <Select
                                        className="form-control-check"
                                        onChange={handleChange}
                                        defaultValue={"30 days"}
                                    >
                                        <Select.Option value="30">30 days</Select.Option>

                                        <Select.Option value="45">45 days</Select.Option>

                                        <Select.Option value="60">60 days</Select.Option>

                                        <Select.Option value="99">Up to 60 days</Select.Option>
                                    </Select>
                                </Form.Item>
                            </div> */}

                            {/* <div className="col-md-3">
                                <Form.Item label="Property Type" name="property_type">
                                    <Select
                                        className="form-control-check"
                                        onChange={handleChange}
                                        defaultValue={"Single Family"}
                                    >
                                        <Select.Option value="1">
                                            Single Family/Townhome
                                        </Select.Option>

                                        <Select.Option value="2">
                                            Condo (less than 4 stories)
                                        </Select.Option>

                                        <Select.Option value="3">
                                            Condo (more than 4 stories)
                                        </Select.Option>

                                        <Select.Option value="4">2 Unit (Duplex)</Select.Option>

                                        <Select.Option value="5">3 Unit (Duplex)</Select.Option>

                                        <Select.Option value="6">4 Unit (Duplex)</Select.Option>
                                    </Select>
                                </Form.Item>
                            </div> */}

                            {/* <div className="col-md-3">
                                <Form.Item label="Occupancy Type" name="occupancy_type">
                                    <Select
                                        className="form-control-check"
                                        onChange={handleChange}
                                        defaultValue={"Primary residence"}
                                    >
                                        <Select.Option value="1">Primary Residence</Select.Option>

                                        <Select.Option value="2">Second Home</Select.Option>

                                        <Select.Option value="3">Investment</Select.Option>
                                    </Select>
                                </Form.Item>
                            </div> */}

                            <div className="col-md-3">
                                <Form.Item
                                    label="Annual Interest Rate"
                                    name="annualInterestRate"
                                    rules={[
                                        {
                                            required: true,

                                            message: "Please input your annual interest rate",
                                        },
                                    ]}
                                >
                                    <Input placeholder="e.g. 5.0" className="form-control" />
                                </Form.Item>
                            </div>

                            <div className="col-md-12 text-center my-5">
                                <button type="submit" className="btn accountBtn w-auto">
                                    Calculate
                                </button>
                            </div>

                            {monthlyPayment && (
                                <div className="result">
                                    <p>
                                        Estimated Monthly Payment:{" "}
                                        <strong>${monthlyPayment.total}</strong>
                                    </p>

                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={chartOptions}
                                    />
                                </div>
                            )}

                            <div className="col-md-12">
                                <div className="lendersRow">
                                    <p>
                                        No Lenders found in your search criteria, Please search with
                                        different Loan details
                                    </p>

                                    <p>
                                        Disclosure : <strong>Rate data provided by</strong>{" "}
                                        <Link to={"/"} className="bottomLink">
                                            RateUpdate.com
                                        </Link>
                                        .
                                        <strong>
                                            {" "}
                                            Displayed by ICB, a division of Mortgage Research Center,
                                            NMLS #1907, Equal Housing Opportunity.
                                        </strong>
                                        Payments do not include taxes, insurance premiums or private
                                        mortgage insurance if applicable. Actual payments will be
                                        greater with taxes and insurance included.
                                        <Link to={"/"} className="bottomLink">
                                            Rate and product details.
                                        </Link>
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="topButtonsRow">
                                            <button type="button" className="btn showBtn me-4">
                                                Show VA
                                            </button>

                                            <button type="button" className="btn showAllBtn">
                                                Show All
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="loanSection">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="loanWrap text-start">
                                                <div className="logo">
                                                    <img
                                                        src={
                                                            "https://m.foolcdn.com/media/affiliates/original_images/PenFed_Logo.png"
                                                        }
                                                        className="img-fluid"
                                                        alt="logo"
                                                    />
                                                </div>

                                                <p className="number">NMLS: 401822</p>
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="loanWrap">
                                                <p className="heading">APR</p>

                                                <p className="text">5.990%</p>

                                                <p className="smallText">30 Year fixed</p>

                                                <button type="button" className="btn loanBtn">
                                                    VA Loan
                                                </button>
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="loanWrap">
                                                <p className="heading">Rate</p>

                                                <p className="text">5.750%</p>

                                                <p className="smallText">45 day lock</p>
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="loanWrap">
                                                <p className="heading">Payment</p>

                                                <p className="text">$962</p>

                                                <p className="smallText">Monthly</p>
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="loanWrap">
                                                <p className="heading">Fees & Points</p>

                                                <p className="text">$4,324</p>

                                                <p className="smallText">Includes 0.375 pts ($618)</p>
                                            </div>
                                        </div>

                                        <div className="col-md-2 d-flex justify-content-center align-items-center">
                                            <div className="detailBtnWrap">
                                                <button type="button" className="btn accountBtn w-100">
                                                    View Details
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="loanSection">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="loanWrap text-start">
                                                <div className="logo">
                                                    <img
                                                        src={
                                                            "https://m.foolcdn.com/media/affiliates/original_images/PenFed_Logo.png"
                                                        }
                                                        className="img-fluid"
                                                        alt="logo"
                                                    />
                                                </div>

                                                <p className="number">NMLS: 401822</p>
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="loanWrap">
                                                <p className="heading">APR</p>

                                                <p className="text">5.990%</p>

                                                <p className="smallText">30 Year fixed</p>
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="loanWrap">
                                                <p className="heading">Rate</p>

                                                <p className="text">5.750%</p>

                                                <p className="smallText">45 day lock</p>
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="loanWrap">
                                                <p className="heading">Payment</p>

                                                <p className="text">$962</p>

                                                <p className="smallText">Monthly</p>
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="loanWrap">
                                                <p className="heading">Fees & Points</p>

                                                <p className="text">$4,324</p>

                                                <p className="smallText">Includes 0.375 pts ($618)</p>
                                            </div>
                                        </div>

                                        <div className="col-md-2 d-flex justify-content-center align-items-center">
                                            <div className="detailBtnWrap">
                                                <button type="button" className="btn accountBtn w-100">
                                                    View Details
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="lendersRow border-0">
                                    <p>
                                        Disclosure : <strong>Rate data provided by</strong>{" "}
                                        <Link to={"/"} className="bottomLink">
                                            RateUpdate.com
                                        </Link>
                                        .
                                        <strong>
                                            {" "}
                                            Displayed by ICB, a division of Mortgage Research Center,
                                            NMLS #1907, Equal Housing Opportunity.
                                        </strong>
                                        Payments do not include taxes, insurance premiums or private
                                        mortgage insurance if applicable. Actual payments will be
                                        greater with taxes and insurance included.
                                        <Link to={"/"} className="bottomLink">
                                            Rate and product details.
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default CalculatePayment;
