import React from "react";
import TopHeader from "./topHeader";
import Navbar from "./navbar";

const Header = () => {
    return(
        <div className="headerWrapper">
            <div className="header">
                <TopHeader />
                <Navbar />
            </div>
        </div>
    );
};

export default Header;