import React from "react";
import MembershipInformation from "./membershipCard";
import LoginInformation from "./loginInfo";
import BillingInformation from "./billingCard";
import CreditCardInformation from "./creditCardInfo";
import TransactionSummary from "./transaction";

const Index = () => {
    return (
        <div className="accountWrapper">
            <div className="innerWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="titleSection">
                                <h5 className="topTitle">My Account</h5>
                                <h3 className="mainTitle">Account Information</h3>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="leftSection">
                                <LoginInformation />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="rightSection">
                                <MembershipInformation />
                                <BillingInformation />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="bottomSection">
                                <CreditCardInformation />
                                <TransactionSummary />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;