import React from "react";
import {
    SliderImageOne
} from "../../constant/images";
import { TbBed, TbVector } from "react-icons/tb";
import { LuBath } from "react-icons/lu";
import { FaLocationDot } from "react-icons/fa6";
import { useGetRecentlyPropertyQuery } from "../../store/getSlice";
import { Spin } from "antd";


const RecentSection = () => {
    const { data: getRecentlyProperty,isLoading } = useGetRecentlyPropertyQuery()

    return (
        <div className="RecentWrapper">
            <div className="card recentCard">
                <div className="card-header">
                    <h4 className="card-title">
                        Recently Viewed
                    </h4>
                </div>
                <div className="card-body">
                    { isLoading  ? (
            <Spin style={{ display: "flex", alignItems: "center", justifyContent: "center" }}  size="large"/>
        ) :getRecentlyProperty?.data?.length ? (getRecentlyProperty?.data.map((item) => {
                        return (

                            <div className="recentProperty" key={item.property_id}>
                                <div className="propertyImage">
                                    <img src={SliderImageOne} className="img-fluid" alt="property" />
                                </div>
                                <div className="propertyDetails">
                                    <h4 className="name">{item?.property.unparsed_address}</h4>
                                    <p className="location"><FaLocationDot /> {`${item?.property.postal_city}, ${item?.property.state_or_province}, ${item?.property.country}`}</p>
                                    <div className="aminities">
                                        <span className="beds"><TbBed /> {item?.property?.bedrooms_total} Beds</span>
                                        <span className="bath"><LuBath /> {item?.property?.bathrooms_total} Baths</span>
                                        <span className="bath"><TbVector /> {item?.property?.lot_size} Sqft</span>
                                    </div>
                                </div>
                            </div>
                        )

                    })):
                    <p>No data found</p>}


                </div>
            </div>
        </div>
    );
}

export default RecentSection;