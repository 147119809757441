
import React from "react";
import { FaArrowRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";


const CTA = () => {
   const navigate = useNavigate()
   
    return (
        <div className="ctaWrapper">
            <div className="container">
                <div className="wrapperInner">
                    <div className="container">
                        <div className="row justify-content-end pos-relative top-3">
                            <div className="col-md-4">
                                <div className="ctaContent">
                                    <h5 className="ctaTitle">Need Help?</h5>
                                    <h4 className="ctaSubTitle">
                                        We're Here to Help You with All Your Property Needs.
                                    </h4>
                                </div>
                            </div>
                            <div className="col-md-4 d-flex justify-content-center align-items-center">
                                <div className="ctaButtonSection">
                                    <div className="ctaButtonWrap">
                                        <button type="button" className="btn ctaBtn" onClick={()=>navigate('/contact-us')}>Contact Us <span className="icon"><FaArrowRight /></span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    );
}

export default CTA;