import React, { useState } from "react";
import { Modal, Form, Input, Table, Button, message } from "antd";

import { useGetUserDetailQuery } from "../../store/getSlice";
import { useAddAddressDetailsMutation, useDeleteAddressDetailsMutation, useUpDateAddressDetailsMutation } from "../../store/apiSlice";

const BillingInformation = () => {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [addressId,setAddressId] = useState()
    const [formType,setFormType] = useState()
  const[addAddressDetails] = useAddAddressDetailsMutation()
  const[upDateAddressDetails] = useUpDateAddressDetailsMutation()
  const[deleteAddressDetails] = useDeleteAddressDetailsMutation()
  const { data: userDetail,refetch } = useGetUserDetailQuery()
  const [messageApi, contextHolder] = message.useMessage();
  const success = (message) => {
      messageApi.open({
          type: 'success',
          content: message,
      });
  };

  const errorMessage = (message) => {
      messageApi.open({
          type: 'error',
          content: message,
      });
  };
    const showModal = (addressData) => {
        setIsModalOpen(true);
        if (addressData) {
            form.setFieldsValue(addressData);  // Set form values with existing address data
        } else {
            form.resetFields();  // Reset form if it's a new address
        }
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const onFinish = async(values) => {
     if(formType === 'Add'){
        try {
            const res = await addAddressDetails(values)
            if (res.data) {
                success('Address added succesfully')
                refetch()
                form.resetFields()
                setIsModalOpen(false);
            } else {
                errorMessage(res.error.data.message)
            }
        } catch (error) {
            errorMessage('An unexpected error occurred')
        }
     }else{
        try {
            const res = await upDateAddressDetails({data:values ,addressId})
            if (res.data) {
                success('Address updated succesfully')
                refetch()
                form.resetFields()
                setIsModalOpen(false);
            } else {
                errorMessage(res.error.data.message)
            }
        } catch (error) {
            errorMessage('An unexpected error occurred')
        }
     }
       

    };
    const handleConfirm = async() => {   
        try {
            const res = await deleteAddressDetails(addressId)
            if (res.data) {
                success(res.data.message)
                refetch()
                setIsDeleteModal(false);
            } else {
                errorMessage(res.error.data.message)
            }
        } catch (error) {
            errorMessage('An unexpected error occurred')
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const columns = [
        {
            title: 'Address',
            render: (data) => (
                 `${data?.address},${data?.country},${data?.city},${data?.state},${data?.billing_zip}`
            ),     
        },
     
        {
            title: 'Options',
          
            render: (data) => (
              
                <div className="d-flex justify-content-evenly">
                  <div className="buttonWrap">
                    <button type="button" className="btn optionBtn" onClick={()=>{showModal(data);setAddressId(data?.id);setFormType('Edit')}}>Edit</button>
                </div>
                 <div className="buttonWrap">
                 <button type="button" className="btn optionBtn" onClick={()=>{setIsDeleteModal(true);setAddressId(data?.id)}}>Delete</button>
             </div>
                </div>
              
            ),
        },
    ];

  
    return (
        <div className="billingWrapper">
            <div className="card accountCard mt-4">
                {contextHolder}
                <div className="card-header">
                    <h4 className="card-title">Billing Information</h4>
                    <button type="button" className="btn editButton" onClick={()=>{showModal();setFormType('Add')}}>Add Address</button>
                </div>
                {/* <div className="card-body">
                    <div className="billingWrap">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label>Address</label>
                                    <p className="userInfo">Gali no.2, Rajapark</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label>State</label>
                                    <p className="userInfo">Rajasthan</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label>City</label>
                                    <p className="userInfo">Jaipur</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label>ZIP</label>
                                    <p className="userInfo">32132</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                  <div className="tableWrapper">
                    <Table
                        className="billingWrap"
                        dataSource={userDetail?.data?.billing_addresses}
                        columns={columns}
                        pagination={false}
                    />
                </div>
            </div>
            <Modal
                title={`${formType} Card Details`}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleOk}
                className="formCard"
                centered
                footer={null}
                okText="Update"
            >
                <div className="modalBody">
                    <Form
                    form={form}
                        name="billingForm"
                        layout="vertical"
                        onFinish={onFinish}                  
                        onFinishFailed={onFinishFailed}
                    >
                        <div className="row">
                            <div className="col-md-12">
                                <Form.Item
                                    name="address"
                                    label="Address"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your current  address!",
                                        },

                                    ]}
                                >
                                    <Input className="form-control" placeholder="Address" />
                                </Form.Item>
                            </div>
                            <div className="col-md-12">
                                <Form.Item
                                    name="country"
                                    label="Country"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your current  country",
                                        },

                                    ]}
                                >
                                    <Input className="form-control" placeholder="Country" />
                                </Form.Item>
                            </div>
                            <div className="col-md-12">
                                <Form.Item
                                    name="state"
                                    label="State"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your current  state",
                                        },

                                    ]}
                                >
                                    <Input className="form-control" placeholder="State" />
                                </Form.Item>
                            </div>
                            <div className="col-md-12">
                                <Form.Item
                                    name="city"
                                    label="City"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your current  city",
                                        },

                                    ]}
                                >
                                    <Input className="form-control" placeholder="City" />
                                </Form.Item>
                            </div>
                            <div className="col-md-12">
                                <Form.Item
                                    name="billing_zip"
                                    label="ZIP"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your current  zip",
                                        },

                                    ]}
                                >
                                    <Input className="form-control" placeholder="Zip" />
                                </Form.Item>
                            </div>
                            <div className="buttonWrapper">
                                    <Button htmlType="submit" className="submitBtn me-4">Save</Button>
                                    <button type="button" className="btn cancelBtn" onClick={handleOk}>Cancel</button>
                                </div>
                        </div>
                    </Form>
                </div>
            </Modal>
            <Modal title="Confirm" open={isDeleteModal} onOk={handleConfirm} onCancel={()=>setIsDeleteModal(false)}>
        <h5>Are you sure want to delete the address?</h5>
       
      </Modal>
        </div>
    );
};

export default BillingInformation;