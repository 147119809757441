import React from "react";
import { Link } from "react-router-dom";
import { SlLocationPin } from "react-icons/sl";
import { LuPhone } from "react-icons/lu";
import { TbMail } from "react-icons/tb";

const TopHeader = () => {
    return(
        <div className="topHeader">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="locationWrap">
                            <p className="locationText">
                                <SlLocationPin /> Abc, 315, Jaipur, 302033
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="topHeaderMenu">
                            <div className="topMenu">
                                <Link to={"tel:+91 987-654-3210"} className="topMenuLink"><LuPhone /> +91 987-654-3210</Link>
                                <Link to={"mailto:support@rainfrog.com"} className="topMenuLink"><TbMail /> support@rainfrog.com</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopHeader;