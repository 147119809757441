import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';
import {
    SliderImageOne,
    SliderImageTwo,
    SliderImageThree
} from "../../constant/images";
import { Link } from "react-router-dom";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";

const EbookSection = ({ className }) => {
    return (
        <div className={"featuredWrapper " + className}>
            <div className="innerWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="titleSection">
                                <h5 className="topTitle">eBooks</h5>
                                <h3 className="mainTitle">Read Our eBooks</h3>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <Swiper
                                spaceBetween={30}
                                slidesPerView={4}
                                navigation={{
                                    nextEl: ".custom-next",
                                    prevEl: ".custom-prev",
                                }}
                                modules={[Navigation]}
                                breakpoints={{
                                    1024: {
                                        slidesPerView: 4,
                                        spaceBetween: 20
                                    },
                                    600: {
                                        slidesPerView: 2,
                                        spaceBetween: 15
                                    },
                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 10
                                    },
                                }}
                            >
                                <SwiperSlide>
                                    <div className="card carouselCard">
                                        <div className="cardImage">
                                            <img src={SliderImageOne} className="img-fluid" alt="card" />
                                        </div>
                                        <div className="contentCard">
                                            <h4 className="name">The Future of Real Estate: Smart Homes and Tech</h4>
                                            <Link to={'#'} className="downloadLink">Download eBook</Link>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="card carouselCard">
                                        <div className="cardImage">
                                            <img src={SliderImageTwo} className="img-fluid" alt="card" />
                                        </div>
                                        <div className="contentCard">
                                            <h4 className="name">Luxury Real Estate: Strategies for Success</h4>
                                            <Link to={'#'} className="downloadLink">Download eBook</Link>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="card carouselCard">
                                        <div className="cardImage">
                                            <img src={SliderImageThree} className="img-fluid" alt="card" />
                                        </div>
                                        <div className="contentCard">
                                            <h4 className="name">Understanding Property Valuation</h4>
                                            <Link to={'#'} className="downloadLink">Download eBook</Link>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="card carouselCard">
                                        <div className="cardImage">
                                            <img src={SliderImageThree} className="img-fluid" alt="card" />
                                        </div>
                                        <div className="contentCard">
                                            <h4 className="name">Understanding Property Valuation</h4>
                                            <Link to={'#'} className="downloadLink">Download eBook</Link>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="card carouselCard">
                                        <div className="cardImage">
                                            <img src={SliderImageThree} className="img-fluid" alt="card" />
                                        </div>
                                        <div className="contentCard">
                                            <h4 className="name">Understanding Property Valuation</h4>
                                            <Link to={'#'} className="downloadLink">Download eBook</Link>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                            <div className="custom-prev navBtn">
                                <GoArrowLeft size={30} />
                            </div>
                            <div className="custom-next navBtn">
                                <GoArrowRight size={30} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EbookSection;
