import React from "react";

const PropertyInformation = (details) => {
   
    return(
        <div className="propertyInfoWrap">
            <div className="infoList">
                <h4 className="title">Features</h4>
                <div className="infoItems">
                    <div className="infoProperty">
                        <span className="infoLabel">Price: </span>
                        <span className="infoText">${details?.details?.price}</span>
                    </div>
                    <div className="infoProperty">
                        <span className="infoLabel">Property Size: </span>
                        <span className="infoText">{details?.details?.lot_size ?? 'No data found'} Sq Ft</span>
                    </div>
                    <div className="infoProperty">
                        <span className="infoLabel">Year Built: </span>
                        <span className="infoText">{details?.details?.year_built?? 'No data found'}</span>
                    </div>
                    <div className="infoProperty">
                        <span className="infoLabel">Heating: </span>
                        <span className="infoText">{details?.details?.heating ?? 'No data found'}</span>
                    </div>
                </div>
            </div>
            <div className="infoList">
                <h4 className="title">Facts</h4>
                <div className="infoItems">
                    <div className="infoProperty">
                        <span className="infoLabel">Country: </span>
                        <span className="infoText">{details?.details?.country ?? 'No data found'}</span>
                    </div>
                    <div className="infoProperty">
                        <span className="infoLabel">Property ID: </span>
                        <span className="infoText">{details?.details?.listing_key ?? 'No data found'}</span>
                    </div>
                    <div className="infoProperty">
                        <span className="infoLabel">MLS Number: </span>
                        <span className="infoText">{details?.details?.mls_number ?? 'No data found'}</span>
                    </div>
                    <div className="infoProperty">
                        <span className="infoLabel">Property Type: </span>
                        <span className="infoText">{details?.details?.property_type ?? 'No data found'}</span>
                    </div>
                    <div className="infoProperty">
                        <span className="infoLabel">Legal Description: </span>
                        <span className="infoText">{details?.details?.legal_description ?? 'No data found'}</span>
                    </div>
                    <div className="infoProperty">
                        <span className="infoLabel">Parcel Number: </span>
                        <span className="infoText">{details?.details?.parcel_number ?? 'No data found'}</span>
                    </div>
                </div>
            </div>
            <div className="infoList">
                <h4 className="title">Description</h4>
                <p>{details?.details?.description ?? 'No data found'}</p>
               
            </div>
        </div>
    );
};

export default PropertyInformation;