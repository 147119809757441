import React, { useState } from "react";
import CTA from "../../components/cta/cta";
import HelpSection from "./help";
import RecentSection from "./recentSection";
import FeaturedSection from "../../components/features/features";
import Filters from "./filters";
import PropertyCard from "./properyCard";
import Map from "./map";


const SearchProperties = () => {
    const [filteredProperties, setFilteredProperties] = useState([]);
    const [propertyCardData, setPropertyCardData] = useState(null)
    const [params,setParams] = useState()
    const handleFilterData = (data) => {
        setFilteredProperties(data);
    };
    const handlePropertyCardData = (data) => {
    
        if(data?.propertyData?.length){
            setPropertyCardData(data?.propertyData?.length);
            setParams(data?.data)
        }else{
            setPropertyCardData(data?.meta?.total); 
        }
       
    };


    return (
        <div className="propertyWrapper searchWrapper">
            <div className="filtersRow">
                <Filters onFilter={handleFilterData} propertyCardData={propertyCardData} params={params}/>
            </div>
            <div className="container">
                <div className="row">

                    <div className="col-lg-8">
                        <PropertyCard filterProperty={filteredProperties} onPropertyCardData={handlePropertyCardData} />
                    </div>
                    <div className="col-lg-4">
                        <Map height={260} />
                        <RecentSection />
                        <HelpSection />
                    </div>
                </div>
            </div>
            <FeaturedSection className="bgGrey" />
            <CTA />
        </div>
    );
};

export default SearchProperties;